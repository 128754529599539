// Handle Pagination

import {useDispatch, useSelector} from "react-redux";
import Page from "./../Page";
import React, {useEffect, useState} from "react";

import storeQueryBuilder from "./storeQueryBuilder";
import urlQueryLoader from "./urlQueryLoader";
import urlQueryBuilder from "./urlQueryBuilder";
import {fetchFilters, fetchRows, updateBoundFilter, updateFilter, updateRow,} from "../../../store/dynamic-page";
import useLoadMoreData from "../../../utils/useLoadMoreData";
import { useLocation } from "react-router-dom";
// import axios from "../../../middleware/axios";

const usePage = ({
                     searchType = "term",
                     pageTitle,
                     api,
                     ModelsView,
                     pageSettings,
                 }) => {
    const dispatch = useDispatch();
    const pageData = useSelector((state) => state.dynamicPage);

    const [innerApi, setInnerApi] = useState(api);
    const [innerModelsView, setInnerModelsView] = useState(ModelsView);

    const fetchList = async (storeQuery, reset) => {
        const urlQuery = urlQueryBuilder(storeQuery, null, searchType, reset);

        dispatch(updateFilter(storeQuery));
        dispatch(
            fetchRows({
                url: innerApi.fetch,
                query: api?.query ? urlQuery + "&" + api.query : urlQuery,
            })
        );
        // loop on the ignored filters and for each one of them remove the filter[filter]=value& from the url
        let newQuery = "";
        if (pageSettings?.ignoredFilters) {
            pageSettings.ignoredFilters.forEach((filter) => {
                newQuery = urlQuery.replace(
                    `&filter[${filter}]=${storeQuery.filters[filter]}`,
                    ""
                );
            });
        }
        if(api.filters)
        dispatch(
            fetchFilters({
                url: innerApi.filters
                    ? innerApi.filters
                    : innerApi.fetch.concat("/filters"),
                query: pageSettings?.ignoredFilters ? newQuery : urlQuery,
            })
        );
    };
    const location = useLocation()
    useEffect(() => {
        const selectedTab = ModelsView?.tabs
            ? Object.entries(ModelsView?.tabs(pageData))?.filter(
                ([key, value]) => value.active === true
            )[0]
            : null;
        const startPageByFilters = {
            search: "",
            sort: api.defaultSort || null,
            page: 1,
            limit: 10,
            filters: {
                ...(ModelsView?.tabs && selectedTab[1]?.filter),
                ...(pageSettings?.product?.filter && {
                    ...pageSettings.product?.filter,
                }),
            },
            tab: selectedTab ? selectedTab[0] : null,
        };
        const storeQuery = storeQueryBuilder(
            startPageByFilters,
            urlQueryLoader(startPageByFilters)
        );

        const mappedFilters = createMappedFilters(
            storeQuery,
            pageData?.boundFilters
        );
        dispatch(updateBoundFilter(mappedFilters));
        fetchList(storeQuery,true);
    }, [location]);


    const fetchData = (params) => fetchRows({
        url: innerApi.fetch,
        ...params,
        query: api?.query ? params.query + "&" + api.query : params.query,
    })
    const { loading, loadMoreData } = useLoadMoreData({
        dispatch,
        pageData,
        fetchData,
    });

    // Pagination Control
    const onHandlePrevious = (pageData) => {
        onPaginationApply(Number(pageData?.filters?.page || 1) - 1);
    };
    const onHandleNext = (pageData) => {
        onPaginationApply(Number(pageData?.filters?.page || 1) + 1);
    };
    const onHandlePage = (page, pageData) => {
        onPaginationApply(page);
    };
    const onHandleLimit = (limit, pageData) => {
        onPaginationApply(1, limit);
    };

    const onPaginationApply = async (page, limit) => {
        const storeQuery = storeQueryBuilder(pageData?.filters, {
            limit: limit || pageData?.filters.limit || 10,
        });
        fetchList(storeQuery);
    };

    const onSearchApply = (search) => {
        const storeQuery = storeQueryBuilder(pageData?.filters, {
            search: search,
        });
        fetchList(storeQuery);
    };

    const onFilterApply = (filters, bound, reset) => {
        const storeQuery = reset
            ? storeQueryBuilder({}, {filters: filters})
            : storeQueryBuilder(pageData?.filters, {filters: filters});
        // create a new variable that consists of the keys of the filters object and the bound object
        dispatch(updateBoundFilter({filters, bound}));
        fetchList(storeQuery);
    };

    const onSortApply = (sort) => {
        const changeSort =
            sort === pageData?.filters?.sort
                ? null
                : pageData?.filters?.sort === `-${sort}`
                    ? sort
                    : `-${sort}`;
        const storeQuery = storeQueryBuilder(pageData?.filters, {
            sort: changeSort,
        });
        fetchList(storeQuery);
    };
    const onTabApply = (tab) => {
        const tabsView = ModelsView?.tabs(pageData);
        const storeQuery = storeQueryBuilder(pageData?.filters, {
            tab: tab,
            filters: {...tabsView[tab].filter},
        });
        if (tabsView[tab].api) {
            setInnerApi(tabsView[tab].api);
        }
        if (tabsView[tab].ModelsView) {
            setInnerModelsView(tabsView[tab].ModelsView);
        } else {
            setInnerModelsView(ModelsView);
        }
        fetchList(storeQuery);
    };

    const refreshPage = () => {
        fetchList(
            storeQueryBuilder(pageData?.filters, {
                page: pageData?.filters?.page,
            })
        );
    };

    const handleUpdateSingleRow = (id, data) => {
        if (Array.isArray(id)) {
            id.forEach((id) => {
                const row = data
                    ? data?.find((row) => row.id === id)
                    : pageData?.rows?.find((row) => row.id === id);
                dispatch(
                    updateRow({
                        id: id,
                        value: {...row, _updatedRow: true},
                    })
                );
            });
        } else {
            const row = data ? data : pageData?.rows?.find((row) => row.id === id);
            dispatch(
                updateRow({
                    id: id,
                    value: {...row, _updatedRow: true},
                })
            );
        }
        // remove the updated row after 5 seconds
        setTimeout(() => {
            dispatch(
                updateRow({
                    id: id,
                    path: "_updatedRow",
                    value: false,
                })
            );
        }, 5000);
    };

    return (
        <Page
            pageData={pageData}
            control={{
                onPaginationApply: onPaginationApply,
                onSearchApply: onSearchApply,
                onFilterApply: onFilterApply,
                onSortApply: onSortApply,
                onHandlePrevious: onHandlePrevious,
                onHandleNext: onHandleNext,
                onHandlePage: onHandlePage,
                onHandleLimit: onHandleLimit,
                onTabApply: onTabApply,
                dispatch: dispatch,
                pageSettings: pageSettings,
                replaceRow: handleUpdateSingleRow,
                loadMoreData: loadMoreData,
                loading: loading,
            }}
            pageTitle={pageTitle}
            modelsView={innerModelsView}
            refreshPage={refreshPage}
        />
    );
};

export default usePage;

export function createMappedFilters(storeQuery, boundFilters) {
    const mappedFilters = {...storeQuery.filters};
    for (const key in boundFilters) {
        if (storeQuery.filters.hasOwnProperty(key)) {
            mappedFilters[key] = boundFilters[key];
        }
    }
    return mappedFilters;
}
