import axios from "axios";
import util from "../../dynamic-page/util";
import React, { useEffect, useState } from "react";
import { GiBrokenShield } from "react-icons/gi";
// You can replace these SVGs with your actual SVG or any other icon
const TruckIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 16.5C8 16.8978 7.84196 17.2794 7.56066 17.5607C7.27936 17.842 6.89782 18 6.5 18C6.10218 18 5.72064 17.842 5.43934 17.5607C5.15804 17.2794 5 16.8978 5 16.5C5 16.1022 5.15804 15.7206 5.43934 15.4393C5.72064 15.158 6.10218 15 6.5 15C6.89782 15 7.27936 15.158 7.56066 15.4393C7.84196 15.7206 8 16.1022 8 16.5ZM15 16.5C15 16.8978 14.842 17.2794 14.5607 17.5607C14.2794 17.842 13.8978 18 13.5 18C13.1022 18 12.7206 17.842 12.4393 17.5607C12.158 17.2794 12 16.8978 12 16.5C12 16.1022 12.158 15.7206 12.4393 15.4393C12.7206 15.158 13.1022 15 13.5 15C13.8978 15 14.2794 15.158 14.5607 15.4393C14.842 15.7206 15 16.1022 15 16.5Z"
      fill="currentColor"
    />
    <path
      d="M3 4C2.73478 4 2.48043 4.10536 2.29289 4.29289C2.10536 4.48043 2 4.73478 2 5V15C2 15.2652 2.10536 15.5196 2.29289 15.7071C2.48043 15.8946 2.73478 16 3 16H4.05C4.16476 15.4349 4.47136 14.9268 4.91787 14.5618C5.36438 14.1969 5.92332 13.9975 6.5 13.9975C7.07668 13.9975 7.63562 14.1969 8.08213 14.5618C8.52864 14.9268 8.83524 15.4349 8.95 16H10C10.2652 16 10.5196 15.8946 10.7071 15.7071C10.8946 15.5196 11 15.2652 11 15V5C11 4.73478 10.8946 4.48043 10.7071 4.29289C10.5196 4.10536 10.2652 4 10 4H3ZM14 7C13.7348 7 13.4804 7.10536 13.2929 7.29289C13.1054 7.48043 13 7.73478 13 8V14.05C13.3217 13.9843 13.6533 13.9826 13.9757 14.045C14.2981 14.1074 14.605 14.2328 14.879 14.4139C15.1529 14.5949 15.3885 14.8282 15.5723 15.1004C15.756 15.3725 15.8844 15.6782 15.95 16H17C17.2652 16 17.5196 15.8946 17.7071 15.7071C17.8946 15.5196 18 15.2652 18 15V10C17.9999 9.73481 17.8946 9.48049 17.707 9.293L15.707 7.293C15.5195 7.10545 15.2652 7.00006 15 7H14Z"
      fill="currentColor"
    />
  </svg>
);

const PaperPlane = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.8939 2.55291C10.8108 2.38703 10.6832 2.24755 10.5253 2.15007C10.3674 2.0526 10.1855 2.00098 9.99995 2.00098C9.8144 2.00098 9.63251 2.0526 9.47462 2.15007C9.31674 2.24755 9.18909 2.38703 9.10595 2.55291L2.10595 16.5529C2.01909 16.7265 1.98463 16.9216 2.00677 17.1144C2.02891 17.3073 2.1067 17.4895 2.23064 17.6389C2.35458 17.7883 2.51931 17.8984 2.70475 17.9557C2.89019 18.0131 3.08831 18.0152 3.27495 17.9619L8.27495 16.5329C8.48392 16.4731 8.66773 16.3469 8.79855 16.1733C8.92937 15.9997 9.00007 15.7883 8.99995 15.5709V10.9999C8.99995 10.7347 9.1053 10.4803 9.29284 10.2928C9.48038 10.1053 9.73473 9.99991 9.99995 9.99991C10.2652 9.99991 10.5195 10.1053 10.7071 10.2928C10.8946 10.4803 10.9999 10.7347 10.9999 10.9999V15.5709C10.9998 15.7883 11.0705 15.9997 11.2013 16.1733C11.3322 16.3469 11.516 16.4731 11.7249 16.5329L16.7249 17.9609C16.9115 18.0143 17.1096 18.0123 17.2951 17.9551C17.4805 17.8979 17.6453 17.7879 17.7693 17.6387C17.8934 17.4894 17.9713 17.3073 17.9936 17.1145C18.0159 16.9217 17.9816 16.7266 17.8949 16.5529L10.8949 2.55291H10.8939Z"
      fill="currentColor"
    />
  </svg>
);

const Smile = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18ZM7 9C7.26522 9 7.51957 8.89464 7.70711 8.70711C7.89464 8.51957 8 8.26522 8 8C8 7.73478 7.89464 7.48043 7.70711 7.29289C7.51957 7.10536 7.26522 7 7 7C6.73478 7 6.48043 7.10536 6.29289 7.29289C6.10536 7.48043 6 7.73478 6 8C6 8.26522 6.10536 8.51957 6.29289 8.70711C6.48043 8.89464 6.73478 9 7 9ZM14 8C14 8.26522 13.8946 8.51957 13.7071 8.70711C13.5196 8.89464 13.2652 9 13 9C12.7348 9 12.4804 8.89464 12.2929 8.70711C12.1054 8.51957 12 8.26522 12 8C12 7.73478 12.1054 7.48043 12.2929 7.29289C12.4804 7.10536 12.7348 7 13 7C13.2652 7 13.5196 7.10536 13.7071 7.29289C13.8946 7.48043 14 7.73478 14 8ZM13.536 13.535C13.6288 13.4421 13.7025 13.3318 13.7527 13.2104C13.8029 13.0891 13.8287 12.959 13.8287 12.8276C13.8287 12.6963 13.8027 12.5662 13.7524 12.4449C13.7021 12.3236 13.6284 12.2133 13.5355 12.1205C13.4426 12.0277 13.3323 11.954 13.2109 11.9038C13.0896 11.8536 12.9595 11.8278 12.8281 11.8278C12.6968 11.8278 12.5667 11.8538 12.4454 11.9041C12.3241 11.9544 12.2138 12.0281 12.121 12.121C11.5584 12.6834 10.7955 12.9994 10 12.9994C9.20451 12.9994 8.44158 12.6834 7.879 12.121C7.69149 11.9334 7.43712 11.8279 7.17185 11.8278C6.90658 11.8277 6.65214 11.933 6.4645 12.1205C6.27686 12.308 6.17139 12.5624 6.1713 12.8276C6.1712 13.0929 6.27649 13.3474 6.464 13.535C6.92831 13.9994 7.47956 14.3678 8.08628 14.6192C8.69299 14.8706 9.34328 14.9999 10 14.9999C10.6567 14.9999 11.307 14.8706 11.9137 14.6192C12.5204 14.3678 13.0717 13.9994 13.536 13.535Z"
      fill="currentColor"
    />
  </svg>
);

const Reply = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.70703 3.29303C7.8945 3.48056 7.99982 3.73487 7.99982 4.00003C7.99982 4.26519 7.8945 4.5195 7.70703 4.70703L5.41403 7.00003H11C12.8565 7.00003 14.637 7.73753 15.9498 9.05028C17.2625 10.363 18 12.1435 18 14V16C18 16.2652 17.8947 16.5196 17.7071 16.7071C17.5196 16.8947 17.2652 17 17 17C16.7348 17 16.4805 16.8947 16.2929 16.7071C16.1054 16.5196 16 16.2652 16 16V14C16 12.6739 15.4732 11.4022 14.5356 10.4645C13.5979 9.52681 12.3261 9.00003 11 9.00003H5.41403L7.70703 11.293C7.80254 11.3853 7.87872 11.4956 7.93113 11.6176C7.98354 11.7396 8.01113 11.8709 8.01228 12.0036C8.01344 12.1364 7.98813 12.2681 7.93785 12.391C7.88757 12.5139 7.81332 12.6255 7.71943 12.7194C7.62553 12.8133 7.51388 12.8876 7.39098 12.9379C7.26809 12.9881 7.13641 13.0134 7.00363 13.0123C6.87085 13.0111 6.73963 12.9835 6.61763 12.9311C6.49562 12.8787 6.38528 12.8025 6.29303 12.707L2.29303 8.70703C2.10556 8.5195 2.00024 8.26519 2.00024 8.00003C2.00024 7.73487 2.10556 7.48056 2.29303 7.29303L6.29303 3.29303C6.48056 3.10556 6.73487 3.00024 7.00003 3.00024C7.26519 3.00024 7.5195 3.10556 7.70703 3.29303Z"
      fill="#9061F9"
    />
  </svg>
);

const Exclamation = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.25709 3.0991C9.02209 1.7391 10.9791 1.7391 11.7431 3.0991L17.3231 13.0191C18.0731 14.3531 17.1101 15.9991 15.5811 15.9991H4.42009C2.89009 15.9991 1.92709 14.3531 2.67709 13.0191L8.25709 3.0991ZM11.0001 13.0001C11.0001 13.2653 10.8947 13.5197 10.7072 13.7072C10.5197 13.8947 10.2653 14.0001 10.0001 14.0001C9.73488 14.0001 9.48052 13.8947 9.29299 13.7072C9.10545 13.5197 9.00009 13.2653 9.00009 13.0001C9.00009 12.7349 9.10545 12.4805 9.29299 12.293C9.48052 12.1055 9.73488 12.0001 10.0001 12.0001C10.2653 12.0001 10.5197 12.1055 10.7072 12.293C10.8947 12.4805 11.0001 12.7349 11.0001 13.0001ZM10.0001 5.0001C9.73488 5.0001 9.48052 5.10546 9.29299 5.29299C9.10545 5.48053 9.00009 5.73488 9.00009 6.0001V9.0001C9.00009 9.26532 9.10545 9.51967 9.29299 9.70721C9.48052 9.89474 9.73488 10.0001 10.0001 10.0001C10.2653 10.0001 10.5197 9.89474 10.7072 9.70721C10.8947 9.51967 11.0001 9.26532 11.0001 9.0001V6.0001C11.0001 5.73488 10.8947 5.48053 10.7072 5.29299C10.5197 5.10546 10.2653 5.0001 10.0001 5.0001Z"
      fill="currentColor"
    />
  </svg>
);

const statusMapping = {
  1: [
    { icon: <TruckIcon />, backgroundColor: "gray-100", textColor: "gray-500" },
    {
      icon: <PaperPlane />,
      backgroundColor: "gray-100",
      textColor: "gray-500",
    },
    { icon: <Smile />, backgroundColor: "gray-100", textColor: "gray-500" },
  ],
  2: [
    { icon: <TruckIcon />, backgroundColor: "gray-100", textColor: "gray-500" },
    {
      icon: <PaperPlane />,
      backgroundColor: "gray-100",
      textColor: "gray-500",
    },
    { icon: <Smile />, backgroundColor: "gray-100", textColor: "gray-500" },
  ],
  10: [
    {
      icon: <TruckIcon />,
      backgroundColor: "green-100",
      textColor: "green-500",
    },
    {
      icon: <PaperPlane />,
      backgroundColor: "gray-100",
      textColor: "gray-500",
    },
    { icon: <Smile />, backgroundColor: "gray-100", textColor: "gray-500" },
  ],
  6: [
    {
      icon: <TruckIcon />,
      backgroundColor: "green-100",
      textColor: "green-500",
    },
    {
      icon: <GiBrokenShield className="w-4 h-4" />,
      backgroundColor: "purple-100",
      textColor: "purple-500",
    },
    { icon: <Smile />, backgroundColor: "gray-100", textColor: "gray-500" },
  ],
  9: [
    {
      icon: <TruckIcon />,
      backgroundColor: "green-100",
      textColor: "green-500",
    },
    { icon: <Reply />, backgroundColor: "purple-100", textColor: "purple-500" },
    { icon: <Smile />, backgroundColor: "gray-100", textColor: "gray-500" },
  ],
  5: [
    {
      icon: <TruckIcon />,
      backgroundColor: "green-100",
      textColor: "green-500",
    },
    {
      icon: <PaperPlane />,
      backgroundColor: "green-100",
      textColor: "green-500",
    },
    { icon: <Smile />, backgroundColor: "green-100", textColor: "green-500" },
  ],
  3: [
    {
      icon: <TruckIcon />,
      backgroundColor: "green-100",
      textColor: "green-500",
    },
    {
      icon: <PaperPlane />,
      backgroundColor: "green-100",
      textColor: "green-500",
    },
    { icon: <Exclamation />, backgroundColor: "red-100", textColor: "red-500" },
  ],
  4: [
    {
      icon: <TruckIcon />,
      backgroundColor: "green-100",
      textColor: "green-500",
    },
    {
      icon: <PaperPlane />,
      backgroundColor: "green-100",
      textColor: "green-500",
    },
    { icon: <Smile />, backgroundColor: "gray-100", textColor: "gray-500" },
  ],
};

const StatusSteps = ({ statusId, list, item }) => {
  // const [statuses, setStatuses] = useState([]);
  // useEffect(() => {
  //   async function getStatuses() {
  //     const res = await axios.get(
  //       `/api/v1/orders/shipment-labels/${item?.id}/statuses`
  //     );
  //     const data = await res.data;
  //     console.log(data);
  //     setStatuses(data?.data);
  //   }
  //   getStatuses();
  // }, []);
  // console.log(statuses);
  const steps = statusMapping[statusId] || [];

  const Button = ({ item, index, selected }) => (
    <div
      className={`
                w-[32px] h-[32px] bg-${
                  item.backgroundColor
                } items-center justify-center flex rounded-full  relative text-${
        item.textColor
      } selected-${selected}
                ${item.backgroundColor === "gray-100" ? "grayStatus" : ""}
                ${item.backgroundColor === "red-100" ? "redStatus" : ""}
                ${item.backgroundColor === "green-100" ? "greenStatus" : ""}
                ${item.backgroundColor === "purple-100" ? "purpleStatus" : ""}

                
            `}
    >
      {item.icon}
    </div>
  );

  const RenderList = () =>
    steps.map((item, index) =>
      list ? (
        <util.popUp
          toggle={(selected) => (
            <Button item={item} index={index} selected={selected} />
          )}
          action={"click"}
          delay={0}
          bottomSpace={1000}
        >
          {list}
        </util.popUp>
      ) : (
        <Button item={item} index={index} />
      )
    );

  return (
    <>
      <style>
        {`
                    .StatusSteps {
                        filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.05)) drop-shadow(0 2px 1px rgba(0, 0, 0, 0.04));
                        z-index: 1;
                        position: relative;
                    }

                    .StatusSteps > div:not(:first-child)::after {
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: -12px;
                        transform: translateY(-50%);
                        display: block;
                        width: 20px;
                        height: 6px;
                        background: #F3F4F6;
                    }
                    .StatusSteps > div:has(.greenStatus):not(:first-child)::after {
                        background:#DEF7EC !important;
                    }
                    .StatusSteps > div:has(.purpleStatus):not(:first-child)::after {
                        background:#EDEBFE !important;
                    }
                    .StatusSteps > div:has(.redStatus):not(:first-child)::after {
                        background:#FDE8E8 !important;
                    }
                    .StatusSteps > div:has(.grayStatus):not(:first-child)::after {
                        background:#F3F4F6 !important;
                    }
                  
                `}
      </style>
      <div className={"flex flex-row gap-[8px] StatusSteps"}>
        <RenderList />
      </div>
    </>
  );
};
export default StatusSteps;
