import React from "react";
import util from "../../dynamic-page/util";
import Util from "../../dynamic-page/util";
import moment from "moment/moment";
import { Avatar } from "flowbite-react";
import EstTransferCost from "../../pickup-points/components/tooltipInput";
import UI from "../../dynamic-form/elements";
import descriptiveContent from "../../../utils/descriptiveContent";
import Icons from "../../orders/icons";
import Note from "../../pickup-points/components/note";

const Stacked = ({ channels }) => {
  return (
    <div className="flex flex-wrap avatar-group gap-2">
      <style>
        {`
          .avatar-group img {
            object-fit: contain !important;
            background-color: #fff;
          }
        `}
      </style>
      <Avatar.Group>
        {channels?.map((channel) => (
          <Util.popUp
            toggle={() => (
              <Avatar key={channel.id} img={channel.logo} rounded stacked />
            )}
            action={"hover"}
          >
            {channel?.name}
          </Util.popUp>
        ))}
      </Avatar.Group>
    </div>
  );
};

const tableView = (list, tableActions, actionList) => {
  return {
    list: list ?? [],
    theme: {
      name: (item) => (
        <div>
          <span className="font-semibold text-[14px] text-gray-900 leading-[14px] ">
            {item?.name}
          </span>
        </div>
      ),
      country: (item) => (
        <div className="flex gap-2 items-center">
          <img
            className="w-[22.4px] h-[16px] object-cover"
            src={item?.country?.flag}
          />
          <span className="text-[14px] leading-normal font-medium text-gray-900">
            {item?.country?.name}
          </span>
        </div>
      ),
      "city, state": (item) => (
        <div>
          <util.popUp
            toggle={(selected) => (
              <div className="flex justify-start     gap-1.5 items-center ">
                <p className="font-medium text-[14px]  leading-[21px]  text-gray-900">
                  {item?.city}, {item?.state?.name}
                </p>
              </div>
            )}
            action="hover"
            forcePosition={"bottom"}
          >
            <div className="flex flex-col gap-1.5   py-1 px-2 z-[999999]">
              <span className="text-[14px] font-medium leading-[14px] text-gray-900">
                Address
              </span>
              <div className="text-gray-500 leading-tight text-[12px] font-normal flex flex-col gap-0.5">
                <span>{item?.street1}</span> <span>{item?.street2}</span>
              </div>
            </div>
          </util.popUp>
        </div>
      ),
      "WAREHOUSES T. COST EST.": (item) => (
        <div className="">
          <EstTransferCost
            item={item}
            type="transfer_cost"
            key="processing"
            onSubmit={tableActions.onUpdateValue}
            buttonText={"Set Transfer Cost"}
            title={"Transfer Cost"}
          />
        </div>
      ),
      "": (item) => (
        <div className="flex gap-4 items-center">
          <div className="flex justify-between items-center gap-1 ">
            <Note item={item} callBack={tableActions.onUpdateNote} />
          </div>
          <button>
            {descriptiveContent(
              <Icons.editOutline
                onClick={() => actionList.edit.onClick(item)}
                className="w-[18px] h-[18px] text-gray-300 cursor-pointer  hover:text-gray-500"
              />,
              "Edit",
              null,
              "top"
            )}
          </button>
          <button>
            {descriptiveContent(
              <Icons.trashBin
                onClick={() => actionList.delete.onClick(item)}
                className="w-[21px] h-[21px] text-gray-300 cursor-pointer  hover:text-gray-500"
              />,
              "Delete",
              null,
              "top"
            )}
          </button>
        </div>
      ),
    },
  };
};
export default tableView;
