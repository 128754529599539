import React from "react";
import {HiExternalLink,} from "react-icons/hi";
import Util from "../../dynamic-page/util";
import util from "../../dynamic-page/util";
// import Components from "../components";
import ArbitNormalBadge from "../../sub-elements/normal-badge";
import priceHandler from "../../../utils/priceHandler";
import Icons from "../../orders/icons";
import UI from "../../dynamic-form/elements";
import Icon from '../icons'
import SupplyCost from "../components/supplyCost";
import components from "../../orders/components";
import InternalNote from "../components/internalNote";
import descriptiveContent from "../../../utils/descriptiveContent";
import ImageMagnifier from "../../dynamic-page/debt/magnifier";



const TableView = (list, handleSavePrice, handleSaveQty, handleConnectProduct, handledisConnectProduct,connect) => {
    const activeStyle = (status) => {
        if (status) {
          return "!text-blue-500";
        } else {
          return "text-gray-300 hover:text-gray-500";
        }
      };

    const nullHandle = (data, chained) => {
        if (!data || chained == null) {
            return "-";
        } else {
            return chained;
        }
    };
    return {
        list: list ?? [],
        theme: {
            "notes": (item) => (
                <div className={"w-[25px]"}>
                    <div className={"grid grid-cols-1  gap-[4px]"}>
                        <Util.popUp
                            toggle={(selected) => (
                                <div className={"relative"}>
                                    <UI.Button
                                        item={{
                                            buttonType: "flat",
                                            className: `p-[2px]  ${activeStyle(
                                                item?.notes?.length > 0
                                            )} text-gray-500 hover:text-gray-300`,
                                        }}
                                    >
                                        <Icons.internalNote className=""/>
                                    </UI.Button>
                                    {item?.notes?.length > 0 && (
                                        <div
                                            className={
                                                "absolute top-[-3px] right-[-3px] bg-white text-blue-500 border border-blue-500 flex text-[8px] min-w-[14px] px-[3px] justify-center items-center font-black rounded-full"
                                            }
                                        >
                                            {item?.notes?.length}
                                        </div>
                                    )}
                                </div>
                            )}
                        >
                            <div className={"w-[525px]"}>
                                <InternalNote
                                    callBack={null}
                                    notes={item?.notes}
                                    id={
                                        item?.id
                                    }
                                />
                            </div>
                        </Util.popUp>
                    </div>
                </div>
            ),
            "type": (item) => (
                <div className="flex flex-col justify-center items-center gap-1 inline-flex">
                    {item.source ? <ArbitNormalBadge
                        title={
                            item.source
                        }
                        bgColor={
                            item.source === 'ARBITBOX' ? "blue-100" : "green-100"
                        }
                        textColor={
                            item.source === 'ARBITBOX' ? "blue-700" : "green-700"
                        }
                    /> : '-'}

                </div>
            ),
            'last update': (item) => {
                return <util.dateDisplay item={item} date={item?.updated_at} />
            },


            channel: (item) => {

                return (
                    <div className="flex flex-col w-full gap-2 ">
                        <div className="flex flex-col gap-1">
                            <div className="flex gap-1 justify-start items-center">
                                {
                                    item?.channel?.supply_channel_type === 'WEBSITE' ?
                                        <Icon.website className={"w-4 h-4 text-gray-300"}/> :
                                        <Icon.wholesaler className={"w-4 h-4 text-gray-300"}/>
                                }
                                <div className="text-gray-900 text-sm font-normal leading-[14px]">
                                    {item?.["channel"]?.name}
                                </div>


                            </div>
                        </div>

                        <div className="">
                            <div className="flex items-center">
                                {item?.identifier ?
                                    <Util.copyText className={"text-[12px] text-blue-700"} text={item?.identifier}
                                                   hint={false}
                                        // callBack={() => openListingModal(modalListing)}
                                    >
                                        {descriptiveContent(item?.identifier ? item?.identifier : "-", 'Identifier')}
                                    </Util.copyText> : '-'}
                                {item?.url && <HiExternalLink className="w-4 h-4 text-gray-300 cursor-pointer"
                                                              onClick={() => {
                                                                  window.open(item?.url, "_blank");
                                                              }}
                                />}
                            </div>
                        </div>
                    </div>
                )
            },


            image: (item) => {
                return <div
                    className={
                        "w-[65px] h-[64px] max-h-[64px]  justify-center items-center flex"
                    }
                >
                    {item?.image || item.product?.image ? (
                            <ImageMagnifier
                                src={item?.image || item.product?.image}
                                className={"!max-h-[64px]"}
                            />
                        ) : (
                            <img
                                src={"/assets/images/noImage.png"}
                                className={"h-[52px] w-[52px]"}
                            />
                        )}
                </div>
            },

            name: (item) => (
                <div
                    className="min-w-[500px] w-[100%] max-w-[500px] p-2  whitespace-pre-wrap   flex-col justify-between items-start inline-flex">
                    {item?.title ? (
                        <util.truncateText maxLines={2} className={"text-[14px]"} tooltip={true}>
                            {item?.title}
                        </util.truncateText>
                    ) : (
                        "-"
                    )}

                    <div className={"flex justify-between w-full items-center"}>

                        {/* {multiple?
        <div className={"text-pink-600 flex flex-row gap-[4px] items-center"}>
            <Icons.collection className={"h-[12px]"}/> Multiple SKU
        </div>
        : */}
                        <div
                            className={"flex flex-row gap-[4px] text-gray-900"}>{util.shortenString(item?.brand?.name, 30)}
                            <util.copyText className={"text-[14px]"} text={item?.brand?.name} hint={false}/>
                        </div>
                        {/* // } */}


                        {item?.product ?
                            <div className="flex items-center gap-[2px] !text-[12px]">
                                
                                <span
                                    className={`w-[20px] h-[20px] rounded-full  flex justify-center items-center text-xs font-medium ${item?.product?.type === 1 ? "text-teal-700 bg-teal-100" : "text-indigo-700 bg-indigo-100"} p-[0px]`}>{item?.product?.type === 1 ? "S" : "G"}</span>
                                <div
                                    className={"text-blue-700 hover:text-blue-800 cursor-pointer flex flex-row gap-[4px] "}
                                    onClick={() => {
                                        window.open(`/product/${item?.product?.id}`, "_blank")
                                    }}
                                >
                                    {item?.product?.brand?.assigned ? <> <img
                                        src={ item?.product?.brand?.assigned&&((item?.product?.brand?.assigned?.profile_image ) || "/assets/images/defaultAvatar.png")}
                                        className={"w-[20px] h-[20px] rounded-full object-cover"}/>
                                    {util.upperCaseString(item?.product?.brand?.assigned?.name, "word")}
                                    </> : <div className="text-gray-400">Unassigned</div>}
                                </div>
                                {/*<util.copyText className={"text-[12px]"} text={item?.product?.apid} hint={false} />*/}
                                {/*{util.shortenString(item?.product?.apid, 10)}*/}
                            </div>
                            :
                            <div className="flex items-center gap-[2px] !text-[12px]">
                                <div className={"flex flex-row gap-[2px]"}
              onClick={() => {
                connect.onClick(item)
              }}
            >
                                    <span
                                        className="w-[20px] h-[20px] rounded-full bg-red-100 flex justify-center items-center text-xs font-medium text-red-800 p-[0px]"><Icons.link
                                        className={"w-[11px] h-[11px]"}/></span>
                                    <div className={"text-red-700 hover:text-red-800 cursor-pointer "}>
                                        Not Connected
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                </div>
            ),

            'price': (item) => (
                <div className="w-[100%] justify-end items-center">
                    <SupplyCost price={
                        {
                            usd_price: priceHandler(item?.usd_price),
                            price: priceHandler(item?.price),
                            data: item
                        }
                    }
                                handleSavePrice={handleSavePrice}
                    />
                </div>

            ),
            'quantity': (item) => (
                <div className="w-[100%] flex justify-end items-center">
                    {item?.quantity ? <components.saveWeight text={priceHandler(item?.quantity)}
                                                             onSave={(t) => handleSaveQty(t, item)}
                                                             className={"min-w-max whitespace-no-wrap group-active:text-green-800 hover:text-green-600 flex text-[24px] text-green-700 font-[700]"}
                                                             outerClassName={'!justify-end'}/> :
                        <Icon.Amount className={"w-5 h-5 text-gray-300 "}/>}

                </div>
            ),
        },
    };
};
export default TableView;
