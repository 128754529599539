import React from "react";
import util from "../../dynamic-page/util";
import Util from "../../dynamic-page/util";
import moment from "moment/moment";
import ImageMagnifier from "../../dynamic-page/debt/magnifier";
import priceHandler from "../../../utils/priceHandler";
import {HiArrowCircleDown, HiArrowCircleUp} from "react-icons/hi";
import copy from "copy-to-clipboard";
import {toast} from "react-toastify";
import quantityHandler from "../../../utils/quantityHandler";
import ArbitNormalBadge from "../../sub-elements/normal-badge";
import {FaWarehouse} from "react-icons/fa";
import {howLongFromNow} from "../../../utils/helper";
import ItemID from "../../dynamic-page/components/ItemID";

const tableView = (list, callBack) => {

    const onCopy = (apid) => {
        copy(apid)
        toast.info('Copied to clipboard', {
            toastId: "success1",
        });
    }

    const nullHandle = (data, chained) => {
        if (!data || !chained) {
            return "";
        } else {
            return chained;
        }
    };

    const convertedListCount = (item) => {
        if (item?.inventory_detail?.length === 0) {
            return [['No inventory details available']];
        }
        if (item?.inventory_detail) {
            return item?.inventory_detail?.map(detail => [
                detail?.name,
                `${detail?.available_quantity}`
            ]);
        }
    }


    const convertedListCost = (item) => {
        if (item?.inventory_detail?.length === 0) {
            return [['No inventory details available']];
        }
        if (item?.inventory_detail) {
            return item?.inventory_detail?.map(detail => [
                detail?.name,
                `${detail?.cost != null ? '$' + priceHandler(detail?.cost) : '-'}`
            ]);
        }
    }

    return ({
        list: list ?? [],
        theme: {
            'adjustment id': (item) => (
                <ItemID id={item.id}/>
            ),
            'created at': (item) => {
                return (
                    <util.dateDisplay item={item} date={item?.created_at} />
                )
            },
            image: (item) => (
                <div
                    className={
                        "w-[56px] h-[64px] max-h-[64px] justify-center items-center flex"
                    }
                >
                    {item?.product?.image ? (
                        <ImageMagnifier
                            src={item?.product?.image}
                            className={"!max-h-[64px] w-[100%]"}
                        />
                    ) : (
                        <img
                            src={"/assets/images/noImage.png"}
                            className={"h-[52px] w-[52px]"}
                        />
                    )}
                </div>
            ),
            product: (item) => (
                <div className="flex flex-col whitespace-pre-wrap items-center justify-center gap-y-[10px] w-[100%]">
                    {item?.product ? (
                        <util.truncateText maxLines={1} className={"text-[14px]"} tooltip={true}>
                            {item?.product?.title}
                        </util.truncateText>
                    ) : (
                        "-"
                    )}

                    <div className={"flex justify-between w-full items-center"}>
                        {(item?.product?.brand?.name) &&
                            <Util.copyText className={"text-[12px] text-gray-600"} text={item?.product?.brand?.name}
                                           hint={true}>
                                {Util.shortenString(item?.product?.brand?.name, 30)}{" "}
                            </Util.copyText>
                        }
                        {item?.product ?
                            <div className="flex items-center gap-[2px] !text-[12px]">
                                <div
                                    className={"text-blue-700 hover:text-blue-800 cursor-pointer flex flex-row gap-[4px] "}
                                    onClick={() => {
                                        window.open(`/product/${item?.product?.id}`, "_blank")
                                    }}
                                >
                                    {item?.product?.brand?.assigned ? <>
                                    <img
                                        src={item?.product?.brand?.assigned?.image_url || "/assets/images/defaultAvatar.png"}
                                        className={"w-[20px] h-[20px] rounded-full object-cover"}/>
                                        {util.upperCaseString(item?.product?.brand?.assigned?.name, "word")}
                                        </> : <div className="text-gray-400">Unassigned</div>}
                                </div>
                            </div>
                            :
                            '-'}

                    </div>
                </div>
            ),
            qty: (item) => (
                <div className='flex items-center text-[16px] font-[400] text-gray-900 justify-center text-center'>
                    {quantityHandler(item?.qty, item?.product?.unit)}
                </div>
            ),
            warehouse: (item) => (
                <div className='flex items-center text-[16px] font-[400] text-gray-900 justify-center text-center'>
                    {nullHandle(item?.warehouse, item?.warehouse?.name)}
                </div>
            ),
            type: (item) => (
                <div className='flex items-center text-[16px] font-[400] text-gray-900 justify-center text-center'>
                    <ArbitNormalBadge
                        title={item?.type === 'in' ? 'IN' : item?.type === 'reserved' ? 'Reserved' : 'OUT'}
                        icon={item?.type === 'in' ?
                            <HiArrowCircleDown className='w-4 h-4 text-green-500'/> : item?.type === 'reserved' ?
                                <FaWarehouse className='w-3 h-3 text-yellow-500'/> :
                                <HiArrowCircleUp className='w-4 h-4 text-red-500'/>}
                        bgColor={item?.type === 'in' ? 'green-100' : item?.type === 'reserved' ? 'yellow-100' : 'red-100'}
                    />
                </div>
            ),
            'created by': (item) => (
                <div className='flex items-center text-[16px] font-[400] text-gray-900 justify-center text-center'>
                    {nullHandle(item?.created_by, item?.created_by?.name)}
                </div>
            ),
            'expiry date': (item) => (
                <div className='flex-col '>
                    {/* display 3 divs to show labels and prices for : subtotal total due and total */}
                    <div className='flex items-center text-[16px] font-[400] text-gray-900 justify-cemter text-center'>
                        <p className='text-gray-900 text-[16px] font-[400]'>{item?.good_acceptance?.expiry_date ? moment(item?.good_acceptance?.expiry_date).format('DD/MM/YYYY') : '-'}</p>
                    </div>
                    {/* <div className='flex items-center text-[16px] font-[400] text-gray-900 justify-between text-center'>
                              <label className='text-gray-900 text-[16px] mr-4 font-[400]' >Manufacturing Date</label>
                              <p className='text-gray-900 text-[16px] font-[400]' >{item?.good_acceptance?.manufacturing_date ? moment(item?.good_acceptance?.manufacturing_date).format('DD/MM/YYYY') : '-'}</p>
                              </div> */}

                </div>
            ),
            reason: (item) => (
                <div className='flex items-center text-[16px] font-[400] text-gray-900 justify-center text-center'>
                    {nullHandle(item, item?.reason)}
                </div>
            ),

        }
    })
}
export default tableView;

