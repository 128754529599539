import util from "../../../dynamic-page/util";
import React from "react";
import { useDispatch } from "react-redux";
import DynamicForm from "../../../dynamic-form";
import { updateRow } from "../../../../store/transfers";
import callEndpoint from "../../../dynamic-page/util/callEndpoint";

const ShippingContent = ({ shipping_content, gtip, id, callBack }) => {
  const [innerData, setInnerData] = React.useState({});
  const [isUpdating, setIsUpdating] = React.useState(false);

  let itemId = null;
  let orderId = id;
  if (typeof orderId === "string" && orderId.includes("/")) {
    itemId = orderId.split("/")[1];
    orderId = orderId.split("/")[0];
  }

  let formItems = [
    {
      name: "shippingContent",
      label: "Shipping Content",
      placeholder: "Enter Shipping Content",
      type: "Text",
      autoFocus: true,
      defaultValue: isUpdating
        ? "..."
        : shipping_content
        ? util.upperCaseString(shipping_content)
        : null,
      clear: false,
      reset: false,
      className: "w-full gap-[0px]",
      disabled: isUpdating,
    },
  ];

  const ref = React.useRef(null);

  const dispatch = useDispatch();
  const handleSaveContent = async (text) => {
    const { shippingContent, gtip } = text;
    setIsUpdating(true);

    if (shippingContent !== innerData.shippingContent) {
      callEndpoint(
        {
          title: "Update Shipping Content",
          url: `transfers/${orderId}/update`,
          method: "put",
          data: { content: shippingContent },
          pureData : true
        }
      ).then((rs) => {
        console.log(rs)
        setIsUpdating(false);
        // util.notifier(rs?.payload?.data);
        if (rs?.type === "success") {
          setInnerData({ ...innerData, shippingContent: shippingContent });
          if (itemId) {
            callBack({
              action: "updateShippingContent",
              data: { id: itemId, text: shippingContent },
            });
          } else {
            dispatch(
              updateRow({
                path: `transfers.[id:${orderId}].content`,
                value: shippingContent,
              })
            );

            dispatch(
              updateRow({
                path: `transfers.[id:${orderId}]._updatedRow`,
                value: true,
              })
            );

            setTimeout(() => {
              dispatch(
                updateRow({
                  path: `transfers.[id:${orderId}]._updatedRow`,
                  value: false,
                })
              );
            }, 5000);

          }
        }
      });
    }

    return true;
  };

  const lineAnimationStyles = {
    width: "50%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.1)",
    position: "absolute",
    left: "0",
    animation: "line-animation 0.5s linear infinite",
  };
  return (
    <>
      {isUpdating && (
        <div
          style={{
            top: "15px",
            left: "10px",
            width: "calc(100% - 20px)",
            height: "5px",
            backgroundColor: "white",
            position: "absolute",
            overflow: "hidden",
            borderRadius: "5px",
            background: "#jjj",
          }}
        >
          <div style={lineAnimationStyles}></div>
        </div>
      )}
      <style>
        {" "}
        {`  @keyframes line-animation { 0% { left: -50%; } 100% { left: 100%; } } @keyframes new-animation { 0% {  background: rgba(164,202, 254, 0.3); } 90% { background: rgba(164,202, 254, 0.3); } 100% { background: white; } } .new-item { animation: new-animation 1.5s linear; }`}{" "}
      </style>
      <div className={"flex-row gap-[8px] flex items-start justify-start"}>
        <div
          className={
            "min-h-[35px] flex flex-col items-center justify-start w-[300px] text-[14px]"
          }
        >
          <DynamicForm
            items={formItems}
            ref={ref}
            style={{
              label: {
                className:
                  "w-full border-t border-gray-200 pt-[12px] !text-gray-400 text-14px",
              },
              container: { className: "gap-[0px]" },
            }}
            onChange={handleSaveContent}
          />
        </div>
      </div>
    </>
  );
};

export default ShippingContent;
