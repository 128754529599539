import React from 'react'
import UI from '../../../../../dynamic-form/elements';
import FooterWeight from '../leftSide/components/footerWeight';

function FooterActions({list, type, actionCallBack, data,callBack,isView}) {
    let compressedValues = {}

    compressedValues = type === 'product' ? {
        "Products": list?.length,
        "Total Actual Weight": data?.status_id === '1' ?  <FooterWeight value={data?.actual_weight} callBack={(d) => {
            console.log(d)
            !isView ?  callBack({
                actual_weight: d
            }) : actionCallBack({
                actual_weight: d || 0
            }, {action: 'update'})
        }} /> : data?.actual_weight,
        "Total Dim. Weight": data?.status_id === '1' ? <FooterWeight value={data?.dimensional_weight} callBack={(d) => {
            console.log(d)
            !isView ? callBack({
                dimensional_weight: d
            }) : actionCallBack({
                dimensional_weight: d || 0
            }, {action: 'update'})
        }} /> : data?.dimensional_weight,
        "Billable Weight": Math.max(
            +data?.dimensional_weight || 0,
            +data?.actual_weight || 0 
        ),
    } : {
        "Orders": list?.length,
            "Total Actual Weight": data?.status_id === '1' ? <FooterWeight value={data?.actual_weight} callBack={(d) => {
                console.log(d)
                isView ? actionCallBack({
                    actual_weight: d || 0
                }, { action: 'update' }) : callBack({
                    actual_weight: d
                })
            }} /> : data?.actual_weight ,
            "Total Dim. Weight": data?.status_id === '1' ? <FooterWeight value={data?.dimensional_weight } callBack={(d) => {
                console.log(d)
                isView ? actionCallBack({
                    dimensional_weight: d || 0
                }, { action: 'update' }) : callBack({
                    dimensional_weight: d
                })
            }} /> : data?.dimensional_weight,
            "Billable Weight": Math.max(
                data?.dimensional_weight ,
                data?.actual_weight
            ),
    }


    return (
        <div className='flex justify-between items-center pl-4 pr-2 !h-[57px] ' style={{
            boxShadow: '0px -1px 2px 0px rgba(0, 0, 0, 0.3)'
            
        }}>
            {/* table summary */}
            {<div className='flex flex-1 gap-4'>
                {
                    list?.length > 0 && Object.keys(compressedValues).map((key, index) => (
                        <div className="  py-1  flex-col justify-between items-end inline-flex">
                            <div className="text-black text-xs font-normal font-['Inter'] leading-3">
                                {key}
                            </div>
                            <div className="text-black text-sm font-semibold font-['Inter'] leading-[21px]">
                                {(compressedValues[key])}
                            </div>
                        </div>
                    ))
                }
            </div>}
            {/* buttons */}
            {(data?.status_id === '1' && data?.order_items?.length > 0 )  &&  <div className="flex  flex-row gap-[8px] justify-end py-[12px]">

                
                <UI.Button
                    item={{
                        title : !data?.content || +data?.billable_weight === 0 ? 'You must add shipping content and weight first' : null ,
                        label: <div className={"flex flex-row"}>Create Shipment </div>,
                        buttonType: "primary",
                        className: "h-[41px] w-[153px] !px-[16px]",
                        disabled: !data?.content || +data?.billable_weight === 0
                    }}
                    onClick={() => {
                        
                            actionCallBack(data, {action: 'shipment'})
                    }}
                />

            </div>}
        </div>
    )
}

export default FooterActions