import React from "react";
import util from "../../dynamic-page/util";
import Icons from "../../orders/icons";

const Weight = ({ weight}) => {
  return (
      <div className={`flex flex-col gap-[4px] items-center justify-center ${
          weight?.selected === 'dimensional' && '!flex-col-reverse'
    }`}>
      {/*{weight?.billable  &&*/}
      
            <util.popUp
              toggle={(selected) => (
                <span
                  className={`opacity-40 ${
                    weight?.selected === "actual" && "!opacity-100 !font-semibold text-[18px] leading-[18px]"
                  } ${
                    selected ? "!opacity-70" : ""
                  } text-purple-600 font-medium flex flex-row gap-[4px] items-center `}
                >
                      <Icons.weightTarazo className={`${
                        weight?.selected === "actual" ? "w-[14px] h-[14px]" : "w-[1em] h-[1em]"
                  }`} />
                  {weight?.actual}
                </span>
              )}
              action={"hover"}
              forcePosition={"top"}
              delay={500}
              toggleClassName={"!cursor-default"}
            >
              Actual Weight
            </util.popUp>
            <util.popUp
              toggle={(selected) => (
                <span
                  className={`opacity-40 ${
                    weight?.selected === "dimensional" && "!opacity-100 !font-semibold text-[18px] leading-[18px]"
                  } ${
                    selected ? "!opacity-70" : ""
                  } text-purple-600 font-medium flex flex-row gap-[4px] items-center text-medium"`}
                >
                  <Icons.weightBox className={`${
                        weight?.selected === "dimensional" ? "w-[14px] h-[14px]" : "w-[1em] h-[1em]"
                  }`} />
                  {weight?.dim}
                </span>
              )}
              action={"hover"}
              forcePosition={"top"}
              delay={500}
              toggleClassName={"!cursor-default"}
            >
              Dimensional Weight
            </util.popUp>
          
    </div>
  );
};

export default Weight;
