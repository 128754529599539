import React, { useState } from "react";
import {
  HiArrowLeft,
  HiCheck,
  HiExternalLink,
  HiInformationCircle,
} from "react-icons/hi";
import moment from "moment";
import { howLongFromNow } from "../../../utils/helper";
import { FiRefreshCw } from "react-icons/fi";
import Util from "../../dynamic-page/util";
import { Tooltip } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import ImageMagnifier from "../../dynamic-page/debt/magnifier";
// import Components from "../components";
import priceHandler from "../../../utils/priceHandler";
import Icons from "../../orders/icons";
import UI from "../../dynamic-form/elements";
import classNames from "classnames";
import ArbitToggle from "../../sub-elements/toggle";
import ListingSales from "../components/listingSales";
import InternalNote from "../components/internalNote";
import Item from "../components/item";
import descriptiveContent from "../../../utils/descriptiveContent";
import SalesChannel from "../../dynamic-page/components/SalesChannel";
import { useDispatch, useSelector } from "react-redux";
import {
  refreshAccountListings,
  updateRow,
} from "../../../store/fc-restocking";
import {
  closeNotification,
  startNotification,
} from "../../dynamic-page/util/notifierLive";
import RefreshSales from "../../dynamic-page/components/RefreshSales";

const TableView = (
  list,
  fulfilmentHandler,
  openSalesHistoryModal,
  openListingModal
) => {
  const navigate = useNavigate();
  const pageData = useSelector((state) => state.fcRestocking);

  const activeStyle = (status) => {
    if (status) {
      return "!text-blue-500";
    } else {
      return "text-gray-300 hover:text-gray-500";
    }
  };
  const calculatorTooltip = (title, value) => {};

  const QuantityTooltip = ({ data }) => {
    if (data?.length > 0)
      return (
        <Util.popUp
          toggle={(selected) => (
            <HiInformationCircle className="w-3 h-3 text-gray-300 cursor-pointer" />
          )}
          action="hover"
          // leftSpace={style?.leftSpace}
        >
          <div className="min-w-[250px] h-[55px]  bg-white rounded flex-col justify-center items-start gap-1.5 inline-flex">
            {data?.map((item) => {
              return (
                <div className="flex flex-row justify-between w-full">
                  <p className="text-gray-900 text-sm font-normal leading-[21px]">
                    {item?.account?.name}
                  </p>
                  <p className="text-gray-900 text-sm font-semibold leading-[21px]">
                    {item?.val || 0}
                  </p>
                </div>
              );
            })}
          </div>
        </Util.popUp>
      );
  };

  // create a higher order component to that return the tooltip
  const Tooltip = ({ title, value }) => {
    return (
      <Util.popUp
        toggle={(selected) => (
          <Icons.calculator className="w-3 h-3 text-gray-300 cursor-pointer" />
        )}
        action="hover"
        // leftSpace={style?.leftSpace}
      >
        <div className=" h-[55px]  bg-white rounded flex-col justify-center items-start gap-1.5 inline-flex">
          <div className="text-center text-gray-900 text-sm font-medium leading-[14px]">
            {title}
          </div>
          <div className="text-gray-500 text-xs font-normal leading-[15px]">
            {value}
          </div>
        </div>
      </Util.popUp>
    );
  };

  const nullHandle = (data, chained) => {
    if (!data || chained == null) {
      return "-";
    } else {
      return chained;
    }
  };

  const typeList = {
    0: "Debit",
    1: "Credit",
  };

  const [refreshing, setRefreshing] = useState("");

  const dispatch = useDispatch();
  const handleRefreshListings = async (item2) => {
    const identifiers = [item2?.sales_channel_listing?.identifier];

    const ids = item2?.id;

    try {
      setRefreshing(item2?.id);

      let a = startNotification({
        title: `Refreshing the Listing`,
      });

      dispatch(refreshAccountListings({ identifiers }))
        .unwrap()
        .then((rs) => {
          setRefreshing("");
          // util.notifier({type : rs.type , message: rs.message});
          closeNotification(a, {
            toast: {
              type: rs.type === "validation_error" ? "error" : rs.type,
              message: rs.message,
              errors: rs.errors,
            },
          });
          if (rs.type === "success") {
            identifiers.map((identifier) => {
              const row = rs.data.find(
                (item) => item.identifier === identifier
              );
              const originalRow = pageData?.fcRestockings?.find(
                (item) => item.sales_channel_listing.identifier === identifier
              );
              dispatch(
                updateRow({
                  path: `fcRestockings.[id:${originalRow.id}]`,
                  value: {
                    ...originalRow,
                    sales_channel_listing: row,
                    _updatedRow: true,
                  },
                })
              );
            });

            setTimeout(() => {
              dispatch(
                updateRow({
                  path: `fcRestockings.[id:${ids}]._updatedRow`,
                  value: false,
                })
              );
            }, 3000);
          }
        });
    } catch (error) {
      // Handle errors if the refresh fails
      console.error("Error refreshing account listings:", error);
    }
  };

  return {
    list: list,
    theme: {
      "": (item) => (
        <div className={"flex gap-1"}>
          <div
            className={
              "grid grid-cols-2 justify-between items-center relative  z-9 gap-[4px]"
            }
          >
            <Util.popUp
              className="!z-[9]"
              toggle={(selected) => (
                <div className={"relative"}>
                  <UI.Button
                    item={{
                      buttonType: "flat",
                      className: `p-[2px]  ${activeStyle(
                        item?.notes?.length > 0
                      )}`,
                    }}
                  >
                    <Icons.internalNote className="w-5 h-5" />
                  </UI.Button>
                  {item?.notes?.length > 0 && (
                    <div
                      className={
                        "absolute top-[-3px] right-[-3px] bg-white text-blue-500 border border-blue-500 flex text-[8px] min-w-[14px] px-[3px] justify-center items-center font-black rounded-full"
                      }
                    >
                      {item?.notes?.length}
                    </div>
                  )}
                </div>
              )}
              // leftSpace={style?.leftSpace}
            >
              <div className={"w-[525px]"}>
                <InternalNote
                  callBack={null}
                  notes={item?.notes}
                  id={item?.id}
                />
              </div>
            </Util.popUp>
            {!item?.sales_channel_listing?.available ? (
              <Util.popUp
                toggle={(selected) => (
                  <Icons.exclamationFill className="w-[20px] h-[20px] text-red-600" />
                )}
                action="hover"
              >
                <div className="text-gray-500 bg-white rounded flex-col justify-center items-start gap-1.5 inline-flex">
                  Unavailable
                </div>
              </Util.popUp>
            ) : (
              ""
            )}
          </div>
        </div>
      ),
      // "last update": (item) => {
      //   const salesListing = item?.sales_channel_listing
      //     ? item?.sales_channel_listing
      //     : item?.sales_channel_listings?.[0];
      //   return <Util.dateDisplay item={item} date={salesListing?.updated_at} />;
      // },
      channel: (item) => {
        const salesListing = item?.sales_channel_listing
          ? item?.sales_channel_listing
          : item?.sales_channel_listings?.[0];

        const modalListing = item;

        return (
          <div className="flex flex-col gap-2">
            <SalesChannel
              item={{
                sold_by_us: item?.sold_by_us,
                channel: {
                  logo: salesListing?.["channel"]?.marketplace?.logo,
                  name: salesListing?.["channel"]?.name,
                },
                identifier_type: salesListing?.identifier_type,
                identifier_value: salesListing?.identifier,
                identifier: salesListing?.identifier,
                url: salesListing?.url,
              }}
              item2={item}
              refreshing={refreshing}
              handleRefreshListings={handleRefreshListings}
              date={salesListing?.updated_at}
              openListingModal={() => openListingModal(modalListing)}
            />
            <RefreshSales
              handleRefreshListings={() => handleRefreshListings(item)}
              item={item}
              refreshing={refreshing}
              date={salesListing?.updated_at}
            />
          </div>
        );
      },

      image: (item) => {
        const salesListing = item?.sales_channel_listing
          ? item?.sales_channel_listing
          : item?.sales_channel_listings?.[0];
        return (
          <div
            className={
              "w-[65px] h-[64px] max-h-[64px]  justify-center items-center flex"
            }
          >
            {salesListing?.main_image_path ? (
              <ImageMagnifier
                src={salesListing?.main_image_path}
                className={"!max-h-[64px]"}
              />
            ) : (
              <img
                src={"/assets/images/noImage.png"}
                className={"h-[52px] w-[52px]"}
              />
            )}
          </div>
        );
      },

      Listing: (item) => (
        <Item
          product={
            item?.sales_channel_listing
              ? [item?.sales_channel_listing?.product]
              : [item?.sales_channel_listings[0]?.product]
          }
          item={item}
        />
      ),

      "LISTING ANALYSIS": (item) => {
        item = item?.sales_channel_listing
          ? item?.sales_channel_listing
          : item?.sales_channel_listings[0];
        const offerCount = item?.total_offer_count;
        return (
          <div className="flex flex-col space-y-[4px] w-[200px] !px-[8px] !py-[12px] ">
            <div className="flex items-center justify-between">
              <Util.popUp
                toggle={(selected) => (
                  <p
                    className={classNames(
                      item?.["buybox_price"]
                        ? "text-teal-600"
                        : "text-teal-200",
                      "text-[24px] leading-[24px] font-[500] flex items-center gap-1"
                    )}
                  >
                    {item?.["buybox_price"]
                      ? "$" + item?.["buybox_price"]
                      : "No BB"}
                  </p>
                )}
                action="hover"
              >
                <div className=" text-gray-500 bg-white rounded flex-col justify-center items-start gap-1.5 inline-flex">
                  Buybox Price
                </div>
              </Util.popUp>

              <div className="flex flex-col justify-end gap-[2px]">
                {/* <p className="text-[12px] leading-[12px] text-gray-400 text-end">
                  avg {consts.currency[item?.["bb_currency"]]}
                  {item?.["bb_avg_price"]}
                </p> */}
                <div className="  justify-between items-center gap-2.5 inline-flex">
                  {item?.buybox_seller_type ? (
                    <Util.popUp
                      toggle={(selected) => (
                        <div
                          className={`text-center ${
                            item?.buybox_seller_type === "FBM"
                              ? "text-blue-700"
                              : "text-orange-700"
                          } text-xs font-normal leading-3`}
                        >
                          {item?.buybox_seller_type}
                        </div>
                      )}
                      action="hover"
                    >
                      <div className="text-gray-500 bg-white rounded flex-col justify-center items-start gap-1.5 inline-flex">
                        Buybox Owner Fulfillment Type
                      </div>
                    </Util.popUp>
                  ) : (
                    ""
                  )}
                  <Util.popUp
                    toggle={(selected) => (
                      <div
                        className="justify-between items-center gap-1 flex cursor-pointer"
                        onClick={() => {
                          window.open(
                            `https://www.amazon.com/gp/offer-listing/${item?.identifier}`,
                            "_blank"
                          );
                        }}
                      >
                        <svg
                          width="10"
                          height="8"
                          viewBox="0 0 10 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M0.799988 1.03848C0.799988 0.879347 0.863202 0.726734 0.975724 0.614213C1.08825 0.501691 1.24086 0.438477 1.39999 0.438477H8.59999C8.75912 0.438477 8.91173 0.501691 9.02425 0.614213C9.13677 0.726734 9.19999 0.879347 9.19999 1.03848C9.19999 1.19761 9.13677 1.35022 9.02425 1.46274C8.91173 1.57526 8.75912 1.63848 8.59999 1.63848H1.39999C1.24086 1.63848 1.08825 1.57526 0.975724 1.46274C0.863202 1.35022 0.799988 1.19761 0.799988 1.03848ZM0.799988 4.03848C0.799988 3.87935 0.863202 3.72673 0.975724 3.61421C1.08825 3.50169 1.24086 3.43848 1.39999 3.43848H8.59999C8.75912 3.43848 8.91173 3.50169 9.02425 3.61421C9.13677 3.72673 9.19999 3.87935 9.19999 4.03848C9.19999 4.19761 9.13677 4.35022 9.02425 4.46274C8.91173 4.57526 8.75912 4.63848 8.59999 4.63848H1.39999C1.24086 4.63848 1.08825 4.57526 0.975724 4.46274C0.863202 4.35022 0.799988 4.19761 0.799988 4.03848ZM4.39999 7.03848C4.39999 6.87935 4.4632 6.72673 4.57572 6.61421C4.68825 6.50169 4.84086 6.43848 4.99999 6.43848H8.59999C8.75912 6.43848 8.91173 6.50169 9.02425 6.61421C9.13677 6.72673 9.19999 6.87935 9.19999 7.03848C9.19999 7.19761 9.13677 7.35022 9.02425 7.46274C8.91173 7.57526 8.75912 7.63848 8.59999 7.63848H4.99999C4.84086 7.63848 4.68825 7.57526 4.57572 7.46274C4.4632 7.35022 4.39999 7.19761 4.39999 7.03848Z"
                            fill="#C81E1E"
                          />
                        </svg>
                        <div className="text-red-700 text-xs font-semibold leading-3">
                          {offerCount != null ? offerCount : "-"}
                        </div>
                      </div>
                    )}
                    action="hover"
                  >
                    <div className="text-gray-500 bg-white rounded flex-col justify-center items-start gap-1.5 inline-flex">
                      Total Offers
                    </div>
                  </Util.popUp>
                </div>
                <Util.popUp
                  toggle={(selected) => (
                    <p className="text-[12px] leading-[12px] text-gray-400 text-end">
                      low{" "}
                      <span className="text-[12px] leading-[12px] text-gray-500 text-end">
                        {item?.["bb_low_price"]
                          ? "$" + item?.["bb_low_price"]
                          : "-"}
                      </span>
                    </p>
                  )}
                  action="hover"
                >
                  <div className="text-gray-500 bg-white rounded flex-col justify-center items-start gap-1.5 inline-flex">
                    Lowest Price
                  </div>
                </Util.popUp>
              </div>
            </div>
            <hr />
            <div>
              <div className="flex items-center justify-between">
                <p
                  className={classNames(
                    item?.["category_rank_degree"]
                      ? "text-purple-600"
                      : "text-purple-200",
                    "text-[24px] leading-[24px] font-[500]"
                  )}
                >
                  {item?.["category_rank_degree"]
                    ? item?.["category_rank_degree"]
                    : "-"}
                </p>
                <div className="flex flex-col justify-end">
                  <Util.popUp
                    toggle={(selected) => (
                      <p className="text-[12px] leading-[12px] text-rose-700 text-end">
                        {item?.["sales_rank"]
                          ? "#" + item?.["sales_rank"]
                          : "-"}
                      </p>
                    )}
                    action="hover"
                  >
                    <div className="text-gray-500 bg-white rounded flex-col justify-center items-start gap-1.5 inline-flex">
                      Sales Rank
                    </div>
                  </Util.popUp>
                  {/* <div className="flex items-center justify-end">
                  {item?.["sales_channel_top"] ? (
                    <p className="text-[12px] leading-[12px] text-gray-400 text-end">
                      top
                    </p>
                  ) : (
                    ""
                  )}
                  &nbsp;
                  <p className="text-[12px] leading-[12px] text-yellow-400 text-end">
                    {item?.["sales_channel_top"] ? item?.["sales_channel_top"] + '%' : "-"}
                  </p>
                </div> */}
                </div>
              </div>
              <div className="flex items-center justify-start text-[12px] leading-[12px] text-rose-700 font-[400]">
                {/* <p className="text-gray-400">in&nbsp;</p>{" "} */}
                {/* {item?.root_category ?? "N/A"} */}
                {item?.root_category
                  ? item?.root_category
                      ?.split(" ")
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(" ")
                  : "N/A"}
              </div>
            </div>
          </div>
        );
      },

      "SUGGESTED RESTOCKING": (item) => (
        <div className="w-[100%] flex justify-start">
          <ListingSales
            item={{
              weekly: {
                upper_title: "7d Restocking",
                upper_value: item?.["fba_7_days_restock"],
                upper_tooltip: (
                  <Tooltip
                    title="Restocking calculation for 7 days"
                    value={item?.tooltips?.["7_days_restocking"]}
                  />
                ),
                lower_title: "Remaining Days",
                lower_value: item?.["fba_7_days_remaining"],
                lower_tooltip: (
                  <Tooltip
                    title="Remaining days of stock for 7 days"
                    value={item?.tooltips?.["7_days_remaining"]}
                  />
                ),
              },
              monthly: {
                upper_title: "30d Restocking",
                upper_value: item?.["fba_30_days_restock"],
                upper_tooltip: (
                  <Tooltip
                    title="Restocking calculation for 30 days"
                    value={item?.tooltips?.["30_days_restocking"]}
                  />
                ),
                lower_title: "Remaining Days",
                lower_value: item?.["fba_30_days_remaining"],
                lower_tooltip: (
                  <Tooltip
                    title="Remaining days of stock for 30 days"
                    value={item?.tooltips?.["30_days_remaining"]}
                  />
                ),
              },
              biMonthly: {
                upper_title: "60d Restocking",
                upper_value: item?.["fba_60_days_restock"],
                upper_tooltip: (
                  <Tooltip
                    title="Restocking calculation for 60 days"
                    value={item?.tooltips?.["60_days_restocking"]}
                  />
                ),
                lower_title: "Remaining Days",
                lower_value: item?.["fba_60_days_remaining"],
                lower_tooltip: (
                  <Tooltip
                    title="Remaining days of stock for 60 days"
                    value={item?.tooltips?.["60_days_remaining"]}
                  />
                ),
              },
            }}
          />
        </div>
      ),

      Supply: (item) => {
        const salesListing = item?.sales_channel_listing
          ? item?.sales_channel_listing
          : item?.sales_channel_listings?.[0];
        return (
          <div className="w-[100%] flex justify-start">
            <ListingSales
              item={{
                weekly: {
                  upper_title: "Requested",
                  upper_value: item?.["supply_request_count"],
                  upper_tooltip: (
                    <HiExternalLink
                      className="w-4 h-4 text-gray-300 cursor-pointer"
                      onClick={() => {
                        if (salesListing?.product?.id)
                          window.open(
                            `/inventory/supply_requests?term=&filter%5Bproduct_id%5D=${salesListing?.product?.id}&limit=20&page=1`,
                            "_blank"
                          );
                      }}
                    />
                  ),
                  lower_title: "Purchased",
                  lower_value: item?.["supply_purchase_count"],
                  lower_tooltip: (
                    <HiExternalLink
                      className="w-4 h-4 text-gray-300 cursor-pointer"
                      onClick={() => {
                        if (salesListing?.product?.id)
                          window.open(
                            `/inventory/supply_purchases?term=&filter%5Bproduct%5D=${salesListing?.product?.id}&limit=20&page=1`,
                            "_blank"
                          );
                      }}
                    />
                  ),
                },
                color: "purple",
              }}
            />
          </div>
        );
      },

      "Fc eligibility": (item) => (
        <div className="flex flex-col justify-between !px-[8px] !py-[12px] gap-[6px] h-full">
          <div className="flex flex-row justify-between gap-4  w-full">
            <div className="flex flex-row items-center justify-center gap-1">
              {descriptiveContent(
                <ArbitToggle
                  status={item?.fba_eligibility ?? false}
                  bgColor={
                    item?.fba_eligibility ? "bg-orange-600" : "bg-gray-400"
                  }
                  handleFulfilment={() => fulfilmentHandler("fba", item?.id)}
                />,
                "Toggle FC Eligibility"
              )}
              <p
                className={`${
                  item?.fba_eligibility ? "text-orange-600" : "text-gray-400"
                } leading-[17.5px] text-[14px] font-medium`}
              >
                FC Restock
              </p>
            </div>
            {descriptiveContent(
              <div
                className="flex flex-row items-center text-orange-700 gap-1 justify-center cursor-pointer"
                onClick={() => {
                  window.open(
                    `/listing/offers?search=&filter%5Bfulfillment%5D=AMAZON_NA&filter%5Bidentifier%5D=${
                      item?.sales_channel_listing?.identifier ||
                      item?.sales_channel_listings[0]?.identifier
                    }&page=1`,
                    "_blank"
                  );
                }}
              >
                <Icons.offerCount />
                <p className="text-orange-700 text-[20px] leading-5 font-medium">
                  {nullHandle(item, item?.fba_offers_count)}
                </p>
              </div>,
              "Offers Count"
            )}
          </div>
          {item?.fba_eligibility ? (
            <>
              <div className="flex flex-row justify-between gap-4  w-full">
                <div className="flex flex-row items-center justify-center">
                  <p className="text-orange-500 text-[20px] leading-5 font-inter font-medium">
                    {descriptiveContent(
                      item.fba_price ? `$` + priceHandler(item.fba_price) : "-",
                      "FBA Price"
                    )}
                  </p>
                </div>
                <div className="flex flex-row items-center justify-center">
                  <p className="text-orange-700 text-lg font-bold">
                    {descriptiveContent(
                      item?.fba_price_margin
                        ? `${item?.fba_price_margin}%`
                        : "-",
                      " FBA Margin"
                    )}
                  </p>
                </div>
              </div>
            </>
          ) : (
            <div className="flex flex-col items-start justify-end w-full text-xl text-gray-500 font-medium leading-5 break-normal ">
              <p className="p-0 m-0">Not Eligible. </p>
            </div>
          )}
          <div className="flex flex-row justify-between gap-1 items-center w-full">
            <HiCheck className="h-[15px] w-[18px]" color="#B43403" />
            <p className="text-[#B43403] text-[16px] leading-5 font-inter font-medium ">
              {descriptiveContent(
                item.fba_available_qty,
                "FBA Available Quantity"
              )}
            </p>
            <QuantityTooltip data={item?.tooltips?.available} />
            <HiArrowLeft className="h-[11px] w-[15px]" color="#FDBA8C" />
            <p className="text-[#B43403] text-[16px] leading-5 font-inter font-normal ">
              {descriptiveContent(
                item.fba_reserved_qty ?? "N/A",
                "FBA Reserved Quantity"
              )}
            </p>
            <QuantityTooltip data={item?.tooltips?.reserved_qty} />
            <HiArrowLeft className="h-[11px] w-[15px]" color="#FDBA8C" />
            <p className="text-[#B43403] text-[16px] leading-5 font-inter font-normal ">
              {descriptiveContent(
                item.fba_inbound_quantity ?? "N/A",
                "FBA Inbound Quantity"
              )}
            </p>
            <QuantityTooltip data={item?.tooltips?.inbound_qty} />
          </div>
        </div>
      ),
    },
  };
};
export default TableView;
