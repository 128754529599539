import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import DynamicTable from "../dynamic-page";
import util from "../dynamic-page/util";
import ConfirmModal from "../dynamic-page/util/confirmModal";
import storeQueryBuilder from "../dynamic-page/util/storeQueryBuilder";
import urlQueryBuilder from "../dynamic-page/util/urlQueryBuilder";
import urlQueryLoader from "../dynamic-page/util/urlQueryLoader";

import { TbCubeSend } from "react-icons/tb";
import filtersView from "./models-view/filters";
import sortView from "./models-view/sort";
import tableView from "./models-view/table";
// ------------------------------
// DEBT
import ArbitProductPage from "../dynamic-page/debt/ArbitProductPage";
import SearchInput from "./../dynamic-page/debt/searchInput";
// ------------------------------
import getActions from "../dynamic-page/util/getActions";

import OrderDetails from "./models-view/details";

import ModalForm from "./models-view/forms";
// Create Shipment Components and Module
import CreateShipments from "./models-view/create-shipment";
import useCreateShipmentInitializer from "./models-view/create-shipment/createShipmentInitializer";
import MergeForm from "./models-view/merge";

import {
  apiActions,
  createSupplyRequest,
  deleteOrder,
  fetchOrders as fetchData,
  fetchOrdersFilters as fetchFilters,
  fetchSingleOrder,
  updateBillableWeight,
  updateBoundFilter,
  updateFilter,
  updateRow
} from "../../store/orders";

import { useLocation } from "react-router-dom";
import UI from "../dynamic-form/elements";
import convertJsonToFormData from "../dynamic-form/util/convertJsonToFormData";
import FooterPagination from "../dynamic-page/elements/FooterPagination";
import TabButton from "../dynamic-page/elements/TabButton";
import notifier from "../dynamic-page/util/notifier";

import moment from "moment";
import { joinArray } from "../../utils/helper";
import useLoadMoreData from "../../utils/useLoadMoreData";
import callEndpoint from "../dynamic-page/util/callEndpoint";
import { createMappedFilters } from "../dynamic-page/util/pageControl";
import zplPrint from "./../print-page/zpl";
import Icons from "./icons";
import Transfers from "./models-view/transfer";
//import {updateNotification} from "../dynamic-page/util/notifier_";
const Orders = ({
  product_id = null,
  displayTitle,
  displayTabGroups = "flex",
  hideFilter,
}) => {
  const dispatch = useDispatch();

  // Initial page at first load.
  const location = useLocation();
  useEffect(() => {
    let filter;
    if (product_id) {
      filter = { product: product_id };
    } else {
      filter = { order_status: 14 };
    }
    const startPageByFilters = {
      search: "",
      sort: "-order_date",
      page: 1,
      filters: filter,
      limit: 10,
      includes:
        "shipmentLabels.fromState.country,suggestedShippingCost,salesAccount,salesAccount.channel,salesAccount.currency,shipmentLabels.internationalCarrier,firstItem",
    };
    const storeQuery = storeQueryBuilder(
      startPageByFilters,
      urlQueryLoader(startPageByFilters)
    );
    const urlQuery = urlQueryBuilder(storeQuery, null, "term", true);

    const mappedFilters = createMappedFilters(
      storeQuery,
      pageData?.boundFilters
    );
    dispatch(updateBoundFilter(mappedFilters));
    dispatch(updateFilter(storeQuery));
    dispatch(fetchData(urlQuery));
    dispatch(fetchFilters(urlQuery));

    let hash = window.location.hash;
    hash = hash.replace("#", "?"); // Replace first '&' with '?'
    const params = new URLSearchParams(hash);
    const action = params.get("details");
    const orderId = params.get("orderId");
    if (orderId) {
      setCalledItemInAction(orderId);
      setModalVisibility("orderDetails");
    }
    // clear hash from url
    window.location.hash = "";
  }, [location]);

  // Get data from store.
  const pageData = useSelector((state) => state.orders);
  const tableData = useSelector((state) => state.orders.orders);

  // Handle selected row in table.
  const [selectedRow, setSelectedRow] = useState([]);

  // Handle form modal.
  const [calledItemInAction, setCalledItemInAction] = useState(null);

  // handle refreshing
  const [refreshing, setRefreshing] = useState(false);
  const [refreshingDate, setRefreshingDate] = useState(null);

  // Handle order details modal.
  const [modalVisibility, setModalVisibility] = useState(null);
  const [confirmationModalVisibility, setConfirmationModalVisibility] =
    useState(null);
  const [confirmationModalData, setConfirmationModalData] = useState(null);
  const [update, setUpdate] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const giveSelectedItemId = (item) => {
    const orderId = item ? [item.id] : selectedRow.map((item) => item.id);
    // setSelectedRow([])
    return orderId;
  };
  const handleCallApiInAction = (action, item, func) => {
    dispatch(
      apiActions({
        data: { orders: giveSelectedItemId(item) },
        action: action,
      })
    ).then((result) => {
      func(result);
    });
  };
  // Initial action list

  const { loading, loadMoreData } = useLoadMoreData({
    dispatch,
    pageData,
    fetchData,
  });

  const actionList = {
    bulkBillableWeight: {
      label: "Edit Billable Weight",
      onClick: (item) => {
        setCalledItemInAction(giveSelectedItemId(item));
        setModalVisibility("bulkBillableWeight");
      },
      bulkOnly: true,
    },

    orderDetails: {
      label: "Order Details",
      onClick: (item) => {
        setSelectedOrder(item);
        setCalledItemInAction(giveSelectedItemId(item));
        setModalVisibility("orderDetails");
      },
      bulkDisable: true,
    },
    markAsShipped: {
      label: "Mark as Shipped",
      onClick: (item) => {
        setConfirmationModalVisibility(true);
        setConfirmationModalData({
          infoText:
            "Are you sure you want to mark the selected order as shipped?",
          confirmText: "Yes, Mark as Shipped",
          cancelText: "No, Cancel",
          callBack: (setSubmit) => {
            callEndpoint({
              title: "Mark as Shipped",
              url: `orders/${item?.id || item}/mark-as-shipped`,
              method: "POST",
            }).then((result) => {
              setSubmit(false);
              if (result?.type === "success") {
                handleRemoveItemFromList(item);
                setConfirmationModalVisibility(false);
              }
            });
          },
        });
      },
      bulkDisable: true,
      condition: (data) =>
        data?.shipping_content &&
        data?.billable_weight &&
        data?.allowed_actions?.includes("MARK_SHIPPED"),
    },
    mergeOrders: {
      label: "Merge Orders",
      onClick: (item) => {
        setCalledItemInAction(item);
        setModalVisibility("mergeOrders");
      },
      bulkDisable: true,
      condition: (data) => data?.customerOrderCount > 1,
    },
    deleteOrder: {
      label: "Delete Order",
      onClick: (item) => {
        setConfirmationModalVisibility(true);
        setCalledItemInAction(giveSelectedItemId(item));
        setConfirmationModalData({
          infoText: "Are you sure you want to delete the selected order/s?",
          confirmText: "Delete",
          cancelText: "Cancel",
          callBack: (setSubmit) => {
            const rows = item?.id ? [item] : selectedRow;
            const ids = item?.id
              ? [item?.id]
              : selectedRow.map((item) => item?.id);

            dispatch(deleteOrder({ orders: ids })).then((result) => {
              if (result?.payload?.type === "error") {
                setConfirmationModalVisibility(false);
                util.notifier({
                  type: result?.payload?.type,
                  message: result?.payload?.message,
                  errors: result?.payload?.errors,
                });
              }

              setSubmit(false);
              if (result?.payload?.type !== "error") {
                setSelectedRow([]);
                // handleRemoveItemFromList(item)
                setConfirmationModalVisibility(false);
                ids.map((id) => {
                  dispatch(
                    updateRow({
                      path: `orders.[id:${id}]`,
                      value: {
                        ...rows.find((item) => item?.id === id),
                        _updatedRow: true,
                        _updatedColor: "red",
                      },
                    })
                  );
                  setTimeout(() => {
                    dispatch(
                      updateRow({
                        path: `orders.[id:${id}]._deletedRow`,
                        value: true,
                      })
                    );
                    setTimeout(() => {
                      dispatch(
                        updateRow({
                          path: `orders.[id:${id}]`,
                        })
                      );
                    }, 1000);
                  }, 1000);
                });
                // dispatch(
                //   updateRow({
                //     path: `orders.[id:${id}]._updatedRow`,
                //     value: true,
                //   })
                // );
                // setTimeout(() => {
                //   dispatch(
                //     updateRow({
                //       path: `orders.[id:${id}]._deletedRow`,
                //       value: true,
                //     })
                //   );
                //   setTimeout(() => {
                //     dispatch(updateRow({ path: `orders.[id:${id}]` }));
                //   }, 1000);
                // }, 3000);

                // setConfirmationModalVisibility(false)
              }
            });
          },
        });

        // }
      },
      condition: (data) => data?.is_manual === true,
    },

    jumpToInitialState: {
      label: "Jump to Initial State",
      onClick: (item) => {
        setConfirmationModalVisibility(true);
        setConfirmationModalData({
          infoText:
            "Are you sure you want to jump the selected order/s to initial state?",
          confirmText: "Yes, Jump to Initial State",
          cancelText: "No, Cancel",
          callBack: (setSubmit) => {
            handleCallApiInAction("jump-to-initial-state", item, (result) => {
              util.notifier(result?.payload?.data);
              if (result?.payload?.data?.type === "success") {
                handleRemoveItemFromList(item);
              }
              setConfirmationModalVisibility(false);
            });
          },
        });
      },
      condition: (data) =>
        data?.allowed_actions?.includes("JUMP_TO_INITIAL_STATE"),
    },

    printLabelAndMarkAsShipped: {
      label: "Print Label & Mark as Shipped",
      bulkDisable: true,
      onClick: (item) => {
        handleCallApiInAction("print-label-mark-shipped", item, (result) => {
          util.notifier(result?.payload?.data);
          if (result?.payload?.data?.type === "success") {
            handleRemoveItemFromList(item);
          }
        });
      },
      condition: (data) =>
        data?.can_mark_as_shipped &&
        data?.allowed_actions?.includes("MARK_SHIPPED"),
    },

    printLabel: {
      label: "Print Label",
      // bulkDisable: true,
      onClick: (item) => {
        handleCallApiInAction("print-label", item, (result) => {
          if (result?.payload?.data?.type === "success") {
            //const fileUrl = result?.payload?.data?.data[0]?.label_file_pdf
            const fileUrl = result?.payload?.data?.data?.map(
              (item) => item?.label_file_png
            );
            fileUrl.forEach((item) => {
              zplPrint.getAndSendFileToPrinter(item);
            });
          }
          util.notifier(result?.payload?.data);
        });
      },
      condition: (data) => data?.allowed_actions?.includes("PRINT_LABEL"),
    },

    viewLabel: {
      label: "View Label",
      bulkDisable: true,
      onClick: (item) => {
        handleCallApiInAction("print-label", item, (result) => {
          if (result?.payload?.data?.type === "success") {
            const fileUrl = result?.payload?.data?.data?.map(
              (item) => item?.label_file_png
            );
            util.pdfViewer(fileUrl, false, `Shipment Label`);
          }
          util.notifier(result?.payload?.data);
        });
      },
      condition: (data) => data?.allowed_actions?.includes("PRINT_LABEL"),
    },

    printLabelAndNotify: {
      label: "Print Label & Notify",
      bulkDisable: true,
      onClick: (item) => {
        handleCallApiInAction("print-label-notify", item, (result) => {
          if (result?.payload?.data?.type === "success") {
            const fileUrl = result?.payload?.data?.data[0]?.label_file_pdf;
            util.pdfViewer(fileUrl, true, `Shipment Label`);
          }
          util.notifier(result?.payload?.data);
        });
      },
      condition: (data) => data?.allowed_actions?.includes("PRINT_LABEL"),
    },

    createShipment: {
      label: "Create Shipment",
      bulkDisable: true,
      onClick: (item) => {
        setCalledItemInAction({
          id: item?.id,
          defaultWarehouse: item?.defaultWarehouse,
          items_qty: item?.items_qty,
          state: item?.ship_to_state?.id,
          billable_weight: item?.billable_weight,
        });
        setModalVisibility("createShipment");
      },
    },
    duplicateOrder: {
      label: "Duplicate Order",
      onClick: (item) => {
        handleCallApiInAction("duplicate", item, (result) => {
          util.notifier(result?.payload?.data);
          onPaginationApply();
        });
      },
      condition: (data) => data?.allowed_actions?.includes("DUPLICATE"),
    },

    requestSupply: {
      label: "Request Supply",
      onClick: (item) => {
        const dataToPost = convertJsonToFormData({
          orders: giveSelectedItemId(item),
        });
        dispatch(createSupplyRequest(dataToPost)).then((result) => {
          util.notifier(result?.payload?.data);
        });
      },
      condition: (data) => data?.allowed_actions?.includes("REQUEST_SUPPLY"),
    },

    refundRequest: {
      label: "Mark as Refunded",
      onClick: (item) => {
        setConfirmationModalVisibility(true);
        setConfirmationModalData({
          infoText:
            "Are you sure you want to request refund for the selected order/s?",
          confirmText: "Yes, Request Refund",
          cancelText: "No, Cancel",
          callBack: (setSubmit) => {
            handleCallApiInAction("mark-as-refunded", item, (result) => {
              util.notifier(result?.payload?.data);
              setSubmit(false);
              if (result?.payload?.data?.type === "success") {
                handleRemoveItemFromList(item);
                setConfirmationModalVisibility(false);
              }
            });
          },
        });
      },
      condition: (data) => data?.allowed_actions?.includes("MARK_REFUNDED"),
    },
    cancelledByBuyer: {
      label: "Cancel Order",
      onClick: (item) => {
        setConfirmationModalVisibility(true);
        setConfirmationModalData({
          infoText: "Are you sure you want to cancel the selected order/s?",
          confirmText: "Yes, Cancel Order",
          cancelText: "No, Cancel",
          callBack: (setSubmit) => {
            handleCallApiInAction("cancelled-by-buyer", item, (result) => {
              util.notifier(result?.payload?.data);
              setSubmit(false);
              if (result?.payload?.data?.type === "success") {
                handleRemoveItemFromList(item);
                setConfirmationModalVisibility(false);
              }
            });
          },
        });
      },
      condition: (data) =>
        data?.allowed_actions?.includes("CANCELLED_BY_CUSTOMER"),
    },
    holdOrder: {
      label: "Hold Order",
      onClick: (item) => {
        setConfirmationModalVisibility(true);
        setConfirmationModalData({
          infoText: "Are you sure you want to hold the selected order/s?",
          confirmText: "Yes, Hold Order",
          cancelText: "No, Cancel",
          callBack: (setSubmit) => {
            handleCallApiInAction("hold-order", item, (result) => {
              util.notifier(result?.payload?.data);
              setSubmit(false);
              if (result?.payload?.data?.type === "success") {
                handleRemoveItemFromList(item, "purple");
                setConfirmationModalVisibility(false);
              }
            });
          },
        });
      },
      condition: (data) => data?.allowed_actions?.includes("HOLD_ORDER"),
    },
    filterByOrderAdress: {
      label: "Filter by Order Address",
      onClick: (item) => {
        onFilterApply(
          {
            order_address_id: item?.ship_to_name,
            sales_account: [item?.sales_account_id],
          },
          null,
          {
            order_address_id: {
              label: item?.customer,
              value: item?.ship_to_name,
            },
            sales_account: [
              {
                label: item?.sales_channel_account?.name,
                value: item?.sales_account_id,
              },
            ],
          },
          "clear"
        );
      },
      condition: false,
    },
    filterState: {
      label: "Filter by Warehouse",
      bulkDisable: true,
      onClick: (item) => {
        onFilterApply({ shipment_label_state_id: [item?.stateId] }, null, {
          shipment_label_state_id: [item?.state],
        });
      },
      condition: false,
    },
  };

  const excludedItems = ["createShipment", "filterState"];
  const AllActionList = Object.keys(actionList)
    .filter((item) => !excludedItems.includes(item))
    .map((item) => actionList[item]);
  // Initial status list

  // Initial sort list
  const sortList = sortView({
    callback: (value) => {
      onSortApply(value);
    },
  });

  // handle actions of statuses
  const actions = (row) => {
    return getActions(row, actionList);
  };

  // Handle Tabs
  const onHandleTab = (item) => {
    if (item === 2) {
      onFilterApply({ order_status: item });
    } else {
      onFilterApply({ order_status: item });
    }
  };

  const onHandleLimit = (limit) => {
    onPaginationApply(1, limit);
  };

  // Apply filter sate to redux store and fetch data.
  const onSearchApply = (search) => {
    setSelectedRow([]);

    const currentStoreFilters = pageData?.filters;
    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      search: search,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term", "");

    dispatch(updateFilter(storeQuery));
    dispatch(fetchFilters(urlQuery));
    dispatch(fetchData(urlQuery));
  };

  const onFilterApply = (filters, sort, bound, search) => {
    // setSelectedRow([]);
    const currentStoreFilters = pageData?.filters;
    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      filters: filters,
      ...(sort && { sort: sort }),
      ...(search === "clear" ? { search: "" } : { search: search }),
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term", "");
    // if all the filters are null then remove the bound filters

    dispatch(updateBoundFilter({ filters, bound }));

    dispatch(updateFilter(storeQuery));
    dispatch(fetchFilters(urlQuery));
    dispatch(fetchData(urlQuery));
  };

  const onSortApply = (sort) => {
    const currentStoreFilters = pageData?.filters;
    const changeSort =
      sort === currentStoreFilters?.sort
        ? null
        : currentStoreFilters?.sort === `-${sort}`
        ? sort
        : `-${sort}`;

    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      sort: changeSort,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term", "");

    dispatch(updateFilter(storeQuery));
    dispatch(fetchFilters(urlQuery));
    dispatch(fetchData(urlQuery));
  };

  const onPaginationApply = async (page, limit) => {
    setSelectedRow([]);
    const currentStoreFilters = pageData?.filters;
    const storeQuery = storeQueryBuilder(currentStoreFilters, {
      limit: limit || currentStoreFilters.limit || 10,
    });
    const urlQuery = urlQueryBuilder(storeQuery, null, "term", "");
    await dispatch(updateFilter(storeQuery), currentStoreFilters);
    await dispatch(fetchFilters(urlQuery));
    await dispatch(fetchData(urlQuery));
  };

  const onSelectRow = (Rows) => {
    setSelectedRow(Rows);
  };

  const { initializeShipment } = useCreateShipmentInitializer();
  const handleSaveShipment = (carrier) => {
    initializeShipment(carrier, (data) => {
      if (data.type === "success") {
        handleRemoveItemFromList({ id: carrier?.order_id });
        setModalVisibility(null);
      }
    });
  };
  const mappedStatuses = tableData?.map((item) => ({
    ...item,
    status: item?.order_status,
  }));

  useEffect(() => {
    if (update && !modalVisibility) {
      const currentRow = pageData?.orders?.find(
        (item) => item?.id === update?.id
      );
      if (currentRow?.order_status !== update?.order_status) {
        const currentStoreFilters = pageData?.filters;
        const storeQuery = storeQueryBuilder(currentStoreFilters, {});
        const urlQuery = urlQueryBuilder(storeQuery, null, "term", "");
        dispatch(fetchFilters(urlQuery));
        handleRemoveItemFromList(currentRow, "red", {
          ...update,
          sales_channel_account: currentRow?.sales_channel_account,
        });
        setUpdate(false);
        return;
      }
      handleUpdateSingleRow(update?.id, "green");
      setUpdate(false);
    }
  }, [update, modalVisibility, calledItemInAction]);

  const handleUpdateSingleRow = async (orderId, color, res) => {
    let ignoredOrders = [];
    if (res) {
      const temp_status = pageData?.orders?.find(
        (item) => item?.id === res?.id
      )?.order_status;
      dispatch(
        updateRow({
          path: `orders.[id:${res?.id}]`,
          value: {
            ...res,
            _updatedRow: true,
            _updatedColor: color,
          },
        })
      );
      // remove the updated row after 5 seconds
      setTimeout(() => {
        dispatch(
          updateRow({
            path: `orders.[id:${res?.id}]._updatedRow`,
            value: false,
          })
        );
      }, 5000);
      if (temp_status === res?.order_status) {
        ignoredOrders.push(res?.id);
      }
    } else {
      const temp_status = pageData?.orders?.find(
        (item) => item?.id === orderId[0]
      )?.order_status;
      await dispatch(fetchSingleOrder(orderId)).then((rs) => {
        rs?.payload?.data?.forEach((orderId) => {
          if (orderId?.order_status === temp_status) {
            ignoredOrders.push(orderId?.id);
          }
          dispatch(
            updateRow({
              path: `orders.[id:${orderId?.id}]`,
              value: {
                ...orderId,
                _updatedRow: true,
                _updatedColor: color,
              },
            })
          );
          // remove the updated row after 5 seconds
          setTimeout(() => {
            dispatch(
              updateRow({
                path: `orders.[id:${orderId?.id}]._updatedRow`,
                value: false,
              })
            );
          }, 5000);
        });
      });
    }

    return ignoredOrders;
  };

  const handleCallBackActions = ({ action, data }) => {
    actionList[action].onClick(data);
  };
  const handleRemoveItemFromList = async (orderId, color, res) => {
    const orderItemId = giveSelectedItemId(orderId);

    const ignoredOrders = await handleUpdateSingleRow(orderItemId, color, res);
    orderItemId.forEach((item) => {
      // if (!ignoredOrders.includes(item)) {
      //   return
      // }
      if (
        pageData?.filters?.filters?.order_status !== null &&
        !ignoredOrders.includes(item)
      ) {
        setTimeout(() => {
          dispatch(updateRow({ path: `orders.[id:${item}]` }));
        }, 1000);
        setSelectedRow([]);
      }
    });

    // updat the filters
    const currentStoreFilters = pageData?.filters;
    const storeQuery = storeQueryBuilder(currentStoreFilters, {});
    const urlQuery = urlQueryBuilder(storeQuery, null, "term", "");
    dispatch(fetchFilters(urlQuery));
  };

  const getLabelType = (item) => {
    if (item?.shipment_labels?.find((label) => label?.is_primary)) {
      return "Label";
    } else if (item?.suggested_shipping_cost?.cost) {
      return "Suggestion";
    } else {
      return "-";
    }
  };

  const exportMapper = (data) => {
    return data?.length
      ? data?.map((item) => {
          return {
            TYPE: item?.type || "-",
            "Order Numbers": joinArray(item?.order_numbers) || "-",
            "Shipstation Order ID": item?.shipstation_order_id || "-",
            "Order Age": item?.order_age || "-",
            "Order Date":
              moment(item?.order_date)?.format("YYYY-MM-DD HH:mm:ss") || "-",
            "Ship By Date":
              moment(item?.ship_by_date)?.format("YYYY-MM-DD") || "-",
            "Order Status": item?.order_status || "-",
            "Sales Channel Status": item?.sales_channel_status || "-",
            "Sales Channel": item?.sales_channel_account?.channel?.name || "-",
            "Sales Channel Account": item?.sales_channel_account?.name || "-",
            "Internal Notes": JSON.stringify(
              item?.internal_notes?.map((note) => {
                return {
                  [note?.created_by?.name]: note?.note,
                };
              })
            ),
            "Customer Note": item?.customer_note || "-",
            "Shipping Content": item?.shipping_content || "-",
            "Billable Weight": item?.billable_weight || "-",
            "Actual Weight": item?.actual_weight || "-",
            "Dimensional Weight": item?.dim_weight || "-",
            "Ship To Name": item?.ship_to_name || "-",
            "Ship To City": item?.ship_to_city || "-",
            "Ship To State": item?.ship_to_state?.name || "-",
            "Ship To Country": item?.ship_to_country?.name || "-",
            "Order Total Quantity": item?.order_total_qty || "-",
            "Order Total Paid": item?.order_total_paid || "-",
            "Original Total Paid": item?.original_total_paid || "-",
            Currency: item?.sales_channel_account?.currency?.code || "-",
            Profit: item?.profit || "-",
            "Profit Margin": item?.profit_margin || "-",
            "Items Paid": item?.profit_calculations?.items_paid || "-",
            "Shipping Paid": item?.profit_calculations?.shipping_paid || "-",
            "Products Cost": +item?.profit_calculations?.products_cost || "-",
            "Shipping Cost": +item?.profit_calculations?.shipping_cost || "-",
            "Shipping Cost Type": getLabelType(item),
            "Primary Shipment Label ID":
              item?.shipment?.shipment_labels?.find(
                (label) => label?.is_primary
              )?.id || "-",
            "Primary Shipment Label Carrier Name":
              item?.shipment?.shipment_labels?.find(
                (label) => label?.is_primary
              )?.carrier?.name || "-",
            "Primary Shipment Label Carrier Service Name":
              item?.shipment?.shipment_labels?.find(
                (label) => label?.is_primary
              )?.carrier_service?.name || "-",
            "Primary Shipment Label Tracking Number":
              item?.shipment?.shipment_labels?.find(
                (label) => label?.is_primary
              )?.tracking_number || "-",
            "Primary Shipment Label Tracking URL":
              item?.shipment?.shipment_labels?.find(
                (label) => label?.is_primary
              )?.tracking_url || "-",
            "Primary Shipment Label Label File":
              item?.shipment?.shipment_labels?.find(
                (label) => label?.is_primary
              )?.label_file_pdf || "-",
            "Primary Shipment Label Warehouse Name":
              item?.shipment?.shipment_labels?.find(
                (label) => label?.is_primary
              )?.warehouse?.name || "-",

            "Item SKU":
              item?.items_count === 1 ? item?.first_item?.item_sku : "Multiple",
            "Item Title":
              item?.items_count === 1
                ? item?.first_item?.item_title
                : "Multiple",
            "Item Image":
              item?.items_count === 1
                ? item?.first_item?.item_image ||
                  item?.first_item?.arbitbox_product?.image
                : "-",
            Qty: item?.items_count === 1 ? item?.first_item?.qty : "-",
            "Unit Price":
              item?.items_count === 1 ? item?.first_item?.unit_price : "-",
            "Original Unit Price":
              item?.items_count === 1
                ? item?.first_item?.original_unit_price
                : "-",
            "Total Price":
              item?.items_count === 1 ? item?.first_item?.total_price : "-",
            "Original Total Price":
              item?.items_count === 1
                ? item?.first_item?.original_total_price
                : "-",
            "Unit Shipping Price":
              item?.items_count === 1
                ? item?.first_item?.unit_shipping_price
                : "-",
            "Original Unit Shipping Price":
              item?.items_count === 1
                ? item?.first_item?.original_unit_shipping_price
                : "-",
            "Arbitbox Product ID":
              item?.items_count === 1 && item?.first_item?.arbitbox_product
                ? item?.first_item?.arbitbox_product?.apid
                : "-",
            "Arbitbox Product Title":
              item?.items_count === 1 && item?.first_item?.arbitbox_product
                ? item?.first_item?.arbitbox_product?.title
                : "-",
            "Arbitbox Product Assignee Name":
              item?.items_count === 1 && item?.first_item?.arbitbox_product
                ? item?.first_item?.arbitbox_product?.assign?.name
                : "-",
            "Arbitbox Product Brand Name":
              item?.items_count === 1 && item?.first_item?.arbitbox_product
                ? item?.first_item?.arbitbox_product?.brand?.name
                : "-",
          };
        })
      : [];
  };

  const headers = [
    "Type",
    "Order Numbers",
    "Shipstation Order ID",
    "Order Age",
    "Order Date",
    "Ship By Date",
    "Order Status",
    "Sales Channel Status",
    "Sales Channel ",
    "Sales Channel Account",
    "Internal Notes",
    "Customer Note",
    "Shipping Content",
    "Billable Weight",
    "Actual Weight",
    "Dimensional Weight",
    "Ship To Name",
    "Ship To City",
    "Ship To State",
    "Ship To Country",
    "Order Total Quantity",
    "Order Total Paid",
    "Original Total Paid",
    "Currency",
    "Profit",
    "Profit Margin",
    "Items Paid",
    "Shipping Paid",
    "Products Cost",
    "Shipping Cost",
    "Shipping Cost Type",
    "Primary Shipment Label ID",
    "Primary Shipment Label Carrier Name",
    "Primary Shipment Label Carrier Service Name",
    "Primary Shipment Label Tracking Number",
    "Primary Shipment Label Tracking URL",
    "Primary Shipment Label Label File",
    "Primary Shipment Label Warehouse Name",
    "Item SKU",
    "Item Title",
    "Item Image",
    "Qty",
    "Unit Price",
    "Original Unit Price",
    "Total Price",
    "Original Total Price",
    "Unit Shipping Price",
    "Original Unit Shipping Price",
    "Arbitbox Product ID",
    "Arbitbox Product Title",
    "Arbitbox Product Assignee Name",
    "Arbitbox Product Brand Name",
    "Order Items",
  ];

  const rowUpdated = useCallback(
    (res) => {
      setUpdate(res);
    },
    [update] // eslint-disable-line react-hooks/exhaustive-deps
  );

  document.addEventListener("DOMContentLoaded", function () {
    if (!CSS.supports("--custom-prop", "value")) {
      var conttElement = document.querySelector(".content .contt");
      if (window.innerWidth >= 1000) {
        conttElement.style.height = "calc(100vh - 230px)";
      }
    }
  });

  const filterCount =
    Object.values(pageData?.filters?.filters ?? {}).filter(
      (item) => item != null && item !== ""
    ).length -
    (pageData?.filters?.filters?.order_status === undefined ||
    pageData?.filters?.filters?.order_status === null ||
    ![1, 2, 3, 10, 14, 15].includes(pageData?.filters?.filters?.order_status)
      ? 0
      : 1);

  const discardedFilters = [1, 2, 3, 10, 14, 15].includes(
    pageData?.filters?.filters?.order_status
  )
    ? ["order_status"]
    : [];

  if (product_id) {
    discardedFilters.concat("product");
  }
  return (
    <>
      <ArbitProductPage.Page className="flex flex-col">
        <ArbitProductPage.Title
          displayTitle={displayTitle}
          title={`Orders${displayTitle ? displayTitle : ""}`}
          allowExport={true}
          loading={pageData.loading}
          exported={{
            exportData: selectedRow?.length > 0 ? selectedRow : tableData,
            exportMapper: exportMapper,
            headers: headers,
          }}
          form={{
            callback: (rs) => {
              setModalVisibility("orderDetails");
            },
          }}
          sort={{
            items: sortList,
            selected: pageData?.filters?.sort,
          }}
          filter={{
            filters: pageData?.boundFilters,
            pageFilters: pageData?.filters?.filters,
            discard: discardedFilters,

            items: filtersView(pageData?.filters?.filters),
            callback: (rs, bound) => {
              onFilterApply(rs, null, bound);
            },
            count:
              Object.values(pageData?.filters?.filters ?? {}).filter(
                (item) => item != null && item !== ""
              ).length -
              (pageData?.filters?.filters?.order_status === undefined ||
              pageData?.filters?.filters?.order_status === null ||
              ![1, 2, 3, 10, 14, 15].includes(
                pageData?.filters?.filters?.order_status
              )
                ? 0
                : 1),
          }}
          actionList={actions(selectedRow)}
          options={[
            {
              callBack: () => {
                setRefreshing(true);
                // dispatch(syncWithMarketplace()).then((rs) => {
                //   util.notifier(rs.payload.data);
                //   setRefreshing(false);
                //   setRefreshingDate(rs.payload.data.data.latest_refresh);
                // });
                if (selectedRow.length === 0) {
                  util.notifier({
                    type: "warning",
                    message: "Please select at least one order.",
                  });
                  setRefreshing(false);
                  return;
                }
                callEndpoint({
                  title: "Refreshing Orders",
                  url: "orders/refresh-orders",
                  method: "POST",
                  data: {
                    orders: selectedRow.map((item) => item.id),
                    force_update: 1,
                  },
                  pureData: true,
                }).then((rs) => {
                  // util.notifier(rs);
                  setRefreshing(false);
                  // update the row if
                  if (rs?.type === "success") {
                    handleUpdateSingleRow(selectedRow.map((item) => item.id));
                  }
                });
              },

              data: refreshing,
              view: ({ callBack, data }) => (
                <UI.Button
                  item={{
                    label: (
                      <div
                        className={`flex flex-row items-center  justify-center gap-[4px] ${
                          !data ? "" : ""
                        }`}
                      >
                        <div className={"flip-x transform scale-x-[-1]"}>
                          <Icons.arrowPath
                            className={`h-5 w-5 ${
                              data ? "animate-spin rotate-[reverse]" : ""
                            }`}
                          />
                        </div>
                        {/* {!data && <TimeAgo timestamp={refreshingDate} /> } */}
                      </div>
                    ),
                    buttonType: "icon",
                    className: "!w-auto !min-w-[28px] !h-[28px] !p-0 ",
                  }}
                  onClick={() => {
                    if (!data) {
                      callBack();
                    }
                  }}
                />
              ),
              condition: () => selectedRow?.length > 0,
            },
            {
              callBack: () => {
                setModalVisibility("transfers");
              },
              order: 0,
              data: refreshing,
              view: ({ callBack, data }) => (
                <UI.Button
                  item={{
                    label: <TbCubeSend className={`h-6 w-6`} />,
                    buttonType: "icon",
                    className: "!min-w-[28px] !h-[28px] !p-0",
                  }}
                  onClick={() => {
                    if (!data) {
                      callBack();
                    }
                  }}
                />
              ),
            },
          ]}
        />
        {/* Tabs and search input are here. */}
        <div
          className={
            "flex flex-row justify-between px-[16px] py-[4px] items-center"
          }
        >
          <SearchInput
            className="w-[362px]"
            placeholder="Search..."
            onSubmit={onSearchApply}
            defaultValue={pageData?.filters?.search}
          />
          {/* {
                        (displayTabGroups !== 'hidden' || displayTabGroups == null) && ( */}
          <TabButton
            filterLoading={pageData?.filterLoading}
            loading={pageData?.loading}
            displayTabGroups={displayTabGroups}
            callBack={onHandleTab}
            active={pageData?.filters?.filters?.order_status}
            //list={pageData?.filtersMeta?.data?.data?.statuses}
            list={[
              {
                id: 14,
                name: "Awaiting Connection",
                count:
                  pageData?.filtersMeta?.data?.data?.statuses?.find(
                    (item) => item?.id === 14
                  )?.count ?? 0,
              },
              {
                id: 3,
                name: "Awaiting Inventory",
                count:
                  pageData?.filtersMeta?.data?.data?.statuses?.find(
                    (item) => item?.id === 3
                  )?.count ?? 0,
              },
              {
                id: 1,
                name: "Awaiting Label",
                count:
                  pageData?.filtersMeta?.data?.data?.statuses?.find(
                    (item) => item?.id === 1
                  )?.count ?? 0,
              },
              {
                id: 15,
                name: "Awaiting Transfer",
                count:
                  pageData?.filtersMeta?.data?.data?.statuses?.find(
                    (item) => item?.id === 15
                  )?.count ?? 0,
              },
              {
                id: 2,
                name: "Ready To Ship",
                count:
                  pageData?.filtersMeta?.data?.data?.statuses?.find(
                    (item) => item?.id === 2
                  )?.count ?? 0,
              },
              {
                id: 10,
                name: "Shipped",
                count:
                  pageData?.filtersMeta?.data?.data?.statuses?.find(
                    (item) => item?.id === 10
                  )?.count ?? 0,
              },
            ]}
            allCount={
              pageData?.filtersMeta?.data?.data?.statuses?.reduce(
                (a, b) => a + (b?.count || 0),
                0
              ) ?? 0
            }
            allTab={true}
          />
          {/* )
                    } */}
        </div>
        {/* Table is here. */}
        <ArbitProductPage.Content className="content">
          <style>
            {!product_id
              ? `
                             @media (768px <= width <= 1000px) {
                                .content .contt {
                                    height:  calc(100vh - ${
                                      filterCount > 0 ? "340" : "300"
                                    }px) !important;
                                }
                            }
                            @media (max-width: 768px) {
                                .content .contt {
                                  height:  calc(100vh - ${
                                    filterCount > 0 ? "370" : "234"
                                  }px) !important;
                                }
                            }
                            @media (1000px <= width <= 1300px) {
                                .content .contt {
                                  height:  calc(100vh - ${
                                    filterCount > 0 ? "270" : "234"
                                  }px) !important;
                                    
                                }
                            }
                            @media (1300px < width ) {
                              .content .contt {
                                height:  calc(100vh - ${
                                  filterCount > 0 ? "250" : "214"
                                }px) !important;
                                  
                              }
                          }
                    `
              : `
                .content .contt {
                  height: calc(100vh - 480px) !important;
                }
                    `}
          </style>

          <DynamicTable
            view={tableView}
            data={tableView(
              mappedStatuses,
              handleCallBackActions,
              pageData?.filters?.filters?.order_status === 3,
              handleUpdateSingleRow,
              onFilterApply,
              handleRemoveItemFromList
            )}
            actions={actions}
            onSelect={onSelectRow}
            selectedRow={selectedRow}
            loading={pageData.loading}
            meta={pageData?.meta}
            appending={loading}
            loadMoreData={loadMoreData}
            style={{
              table: {
                style: { width: "100%", minWidth: "100%" },
              },
              outerContainer: "min-w-full",
              header: {
                th: "!text-[14px] !font-semibold ",
                className:
                  "justify-start !pl-[8px] !pr-[8px] !h-[40px] !text-[14px] font-semibold",
                // "tracking id": { className: "!pr-[30px]" },
                image: { className: "hidden" },
                control: { className: "!py-[0px] hidden" },
                profit: {
                  th:
                    pageData?.filters?.filters?.order_status === 14
                      ? "hidden"
                      : "",
                  className: "!justify-center",
                },
                "order date": {
                  th: "!justify-start !max-w-[148px]",
                  className: "!justify-start !max-w-[148px]",
                },
                weight: {
                  className: "!justify-center",
                },
                qty: {
                  className: "!justify-center",
                  // td: "!w-[62px]",
                },
                item: {
                  className: "!justify-center",
                },
                status: {
                  className: "!justify-center",
                },
              },
              row: {
                className:
                  "!px-[8px] !py-[0px] !h-[63px] !items-center !justify-center flex text-[12px] !whitespace-normal ",
                td: "!h-[64px]",
                // "tracking id": { className: "!pr-[30px]" },
                image: { className: "!py-[0px] !px-0" },
                "order id": { className: "!justify-start " },
                "ship to": { className: "!justify-start !items-center" },
                item: {
                  className: "!whitespace-normal !min-w-[300px] m !pl-1 !pr-2",
                },
                "order date": {
                  className: "!justify-start !w-[148px]",
                  td: "!w-[148px]",
                },
                _actions: {
                  className: "!py-[0px]",
                  td: "!w-[84px]",
                  innerClass: "!gap-0",
                },
                qty: {
                  className: "!w-[62px]",
                  td: "!w-[62px]",
                },
                weight: {
                  className: "!w-[120px]",
                  td: "!w-[120px]",
                },
                profit: {
                  td:
                    pageData?.filters?.filters?.order_status === 14
                      ? "hidden !w-[84px]"
                      : " !w-[84px]",
                  className: "!w-[84px]",
                },
              },
            }}
          />
        </ArbitProductPage.Content>
        <FooterPagination
          meta={{ ...pageData?.meta, limit: pageData?.filters?.limit }}
          pageData={pageData}
          loading={pageData.loading}
          onLimit={onHandleLimit}
          resultsText="orders"
        />
      </ArbitProductPage.Page>

      {/* Form Modal is here. */}
      {modalVisibility === "createShipment" && (
        <div className={"!text-[14px]"}>
          {/* this component calling from here and shipment label, in 2 place */}
          <CreateShipments
            order={calledItemInAction}
            cancel={() => {
              setCalledItemInAction(null);
              setModalVisibility(null);
            }}
            awaiting_transfer={
              pageData?.filtersMeta?.data?.data?.statuses?.find(
                (item) => item?.name === "Awaiting Transfer"
              )?.count
            }
            submit={(data) => {
              handleSaveShipment(data, calledItemInAction);
            }}
          />
        </div>
      )}

      {modalVisibility === "orderDetails" && (
        <div className={"!text-[14px]"}>
          <OrderDetails
            order={calledItemInAction}
            selectedOrder={selectedOrder}
            cancel={() => {
              setSelectedOrder(null);
              setCalledItemInAction(null);
              setModalVisibility(null);
            }}
            update={(data) => {
              rowUpdated(data);
            }}
            openOrder={(orderId) => {
              setCalledItemInAction(orderId);
              setModalVisibility("orderDetails");
            }}
            submit={() => {
              onPaginationApply();
              setCalledItemInAction(null);
              setModalVisibility(null);
            }}
          />
        </div>
      )}
      {modalVisibility === "transfers" && (
        <div className={"!text-[14px]"}>
          <Transfers
            order={calledItemInAction}
            cancel={() => {
              // handleUpdateSingleRow(calledItemInAction)
              setCalledItemInAction(null);
              setModalVisibility(null);
            }}
            submit={() => {
              setCalledItemInAction(null);
              setModalVisibility(null);
            }}
            createShipment={(data) => {
              setCalledItemInAction(data);
              setModalVisibility("createShipment");
            }}
          />
        </div>
      )}
      {modalVisibility === "bulkBillableWeight" && (
        <div className={"!text-[14px]"}>
          <ModalForm
            title={"Bulk Billable Weight"}
            form={(data) => [
              {
                name: "weight",
                label: "Billable Weight",
                placeholder: "Enter billable weight",
                type: "Number",
              },
            ]}
            actions={[
              {
                label: "Save Billable Weight",
                onClick: (data) => {
                  const w = data?.weight;
                  calledItemInAction.map(async (item) => {
                    await dispatch(
                      updateBillableWeight({
                        orderId: item,
                        billable_weight: Number(w),
                      })
                    ).then((rs) => {
                      if (rs?.meta?.requestStatus === "fulfilled") {
                        const w = rs?.payload?.data?.data?.billable_weight;
                        notifier({
                          message: `Billable Weight updated to ${w}`,
                          type: "success",
                        });
                        dispatch(fetchSingleOrder(item)).then((rs) => {
                          dispatch(
                            updateRow({
                              path: `orders.[id:${item}]`,
                              value: rs.payload.data[0],
                            })
                          );
                        });
                      } else {
                        notifier({
                          message: `Update Billable Weight failed`,
                          type: "error",
                        });
                      }
                    });
                  });
                  setCalledItemInAction(null);
                  setModalVisibility(null);
                },
              },
            ]}
            cancel={() => {
              //handleUpdateSingleRow(calledItemInAction)
              setCalledItemInAction(null);
              setModalVisibility(null);
            }}
            sourceData={calledItemInAction}
          />
        </div>
      )}
      {modalVisibility === "mergeOrders" && (
        <MergeForm
          parentItem={calledItemInAction}
          show={modalVisibility === "mergeOrders"}
          onClose={() => {
            setModalVisibility(null);
          }}
          callBack={() => {
            onPaginationApply();
          }}
          updateRows={(items) => {}}
          product_id={product_id}
          // onSubmit={onConnect}
        />
      )}
      {confirmationModalVisibility && (
        <ConfirmModal
          show={confirmationModalVisibility}
          infoText={
            confirmationModalData?.infoText ||
            "Are you sure you want to mark the selected order/s as shipped?"
          }
          onClose={() => setConfirmationModalVisibility(false)}
          confirmText={
            confirmationModalData?.confirmText || "Yes, Mark as Shipped"
          }
          confirmColor="green"
          cancelText="Cancel"
          cancelColor="red"
          styles={{
            confirmButton: "!bg-green-50 ",
            cancelButton: "!bg-red-50 ",
            infoText: "!font-medium",
          }}
          onConfirm={(setSubmit) => {
            confirmationModalData?.callBack
              ? confirmationModalData?.callBack(setSubmit)
              : handleCallBackActions({
                  action: "markAsShipped",
                  data: { id: calledItemInAction[0] },
                });
          }}
        />
      )}
    </>
  );
};

export default Orders;
