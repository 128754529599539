import Icons from "../../orders/icons";
import UI from "../../dynamic-form/elements";
import uti from "../../dynamic-page/util";
import util from "../../dynamic-page/util";
import React, { useEffect, useState } from "react";
// import ShipmentLabelList from "./shipmentLabelList";
import { useDispatch, useSelector } from "react-redux";
import zplPrint from "../../print-page/zpl";
import { FaTruckFast } from "react-icons/fa6";
import descriptiveContent from "../../../utils/descriptiveContent";

const ShipmentLabel = ({ shipment, callBack }) => {
  const dispatch = useDispatch();

  const [shipmentData, setShipmentData] = useState(null);
  const data = useSelector((state) => state.orders.orders);

  useEffect(() => {
    setShipmentData(shipment);
  }, [data, shipment]);

  const handlePrintLabel = (print) => {
    callBack({
      action: "printLabel",
      data: {
        file: shipment?.label_file_png
      },
    });
  };
  const handleViewLabel = (print) => {
    callBack({
      action: "viewLabel",
      data: {
        file: shipment?.label_file_png
      },
    });
  };

  return (
    <div className={"max-w-[300px] w-full "}>
      {shipmentData && (
        <div
          className={"flex flex-col gap-[4px]  justify-start w-full"}
          style={{ width: "100%" }}
        >
          <div
            className={
              "flex flex-row justify-between items-center gap-[16px] w-full"
            }
            style={{ width: "100%" }}
          >
            <div>
              <div
                className={
                  "flex flex-row gap-[8px] box-content w-full items-center "
                }
              >
                {descriptiveContent(
                  shipment?.from_state?.country?.flag ? (
                    <img
                      className={"w-[22.4px] h-[16px]"}
                      src={shipment?.from_state?.country?.flag}
                    />
                  ) : (
                    <FaTruckFast className="text-gray-500 w-[15px] h-{15px]" />
                  ),
                  shipment?.from_state?.country?.name
                )}
                {descriptiveContent(
                  shipment?.international_carrier?.logo ? (
                    <img
                      className={"w-[16px] h-[16px]"}
                      src={shipment?.international_carrier?.logo}
                    />
                  ) : (
                    <FaTruckFast className="text-gray-500 w-[15px] h-{15px]" />
                  ),
                  shipment?.international_carrier?.name
                )}
                <span className={" truncate flex flex-row gap-[4px] items-center"}>
                  <span onClick={() => window.open(shipment?.tracking_url, "_blank")} className="cursor-pointer text-[12px] leading-[12px] font-medium text-blue-700 ">
                    {/* {util.shortenString(shipment?.tracking?.number, 20)} */}
                    {shipment?.tracking_number}
                  </span>
                  <util.copyText
                    className={"text-[12px]"}
                    text={shipment?.tracking_number}
                    hint={false}
                  />
                </span>
              </div>
            </div>
          </div>

          <div
            className={"flex flex-row w-full justify-between gap-[16px]"}
            style={{ width: "100%" }}
          >
            <span className="text-[14px] leading-[21px] font-semibold text-pink-700 ">
              ${shipment?.billed_cost}
            </span>
            <span className={"text-gray-600 min-w-[150px] flex-grow"}>
              <util.expandableText
                maxLines={1}
                className={"text-[12px] "}
                tooltip={true}
                forcePosition={"top"}
                tooltipClassName={"text-[14px] text-gray-900 !max-w-[350px]"}
                action={"hover"}
                delay={500}
                hideCopy={true}
              >
                {uti.upperCaseString(shipment?.carrier_service?.name, "word")}
              </util.expandableText>
            </span>
            <div
              className={
                "text-blue-600 h-[16px] flex flex-row items-center gap-[4px]"
              }
            >
              <UI.Button
                item={{ buttonType: "flat", title: "Show Label" }}
                onClick={handleViewLabel}
              >
                <svg class="w-[14px] h-[14px] text-gray-300 hover:text-gray-500 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                  <path fill-rule="evenodd" d="M4.998 7.78C6.729 6.345 9.198 5 12 5c2.802 0 5.27 1.345 7.002 2.78a12.713 12.713 0 0 1 2.096 2.183c.253.344.465.682.618.997.14.286.284.658.284 1.04s-.145.754-.284 1.04a6.6 6.6 0 0 1-.618.997 12.712 12.712 0 0 1-2.096 2.183C17.271 17.655 14.802 19 12 19c-2.802 0-5.27-1.345-7.002-2.78a12.712 12.712 0 0 1-2.096-2.183 6.6 6.6 0 0 1-.618-.997C2.144 12.754 2 12.382 2 12s.145-.754.284-1.04c.153-.315.365-.653.618-.997A12.714 12.714 0 0 1 4.998 7.78ZM12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" clip-rule="evenodd"/>
                </svg>

              </UI.Button>

              <UI.Button
                item={{ buttonType: "flat", title: "Print Label" }}
                onClick={handlePrintLabel}
              >
                <svg class="w-[14px] h-[14px] text-gray-300 hover:text-gray-500 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M8 3a2 2 0 0 0-2 2v3h12V5a2 2 0 0 0-2-2H8Zm-3 7a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h1v-4a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v4h1a2 2 0 0 0 2-2v-5a2 2 0 0 0-2-2H5Zm4 11a1 1 0 0 1-1-1v-4h8v4a1 1 0 0 1-1 1H9Z" clip-rule="evenodd"/>
</svg>

              </UI.Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShipmentLabel;
