import React, {useEffect, useState} from "react";
import axios from "axios";
import AsyncSelect from "react-select/async";
import debounce from "lodash.debounce";
import style from "./select-util/style";
import returnValue from "./select-util/returnValue";

const CustomAsyncSelect = ({
                               item,
                               value,
                               validationMessage,
                               handleChange,
                               bind,
                               formData,
                               selectedValue,
                           }) => {
    const fetchProducts = async (params) => {
        let finalUrl = item.api.url;
        if (params) finalUrl = finalUrl.concat(params);
        const response = await axios.get(finalUrl, {withCredentials: true});
        return response.data;
    };
    useEffect(() => {
        if (!value) {
            setProduct("");
        }
    }, [value]);

    useEffect(() => {
        // Fetch default options when component mounts
        const fetchInitialOptions = async () => {
            const response = await fetchProducts(
                item?.api?.query ? item.api.query.replace("{query}", "") : "?not_deleted=true"
            );
            setOptions(response.data?.map(item.api.optionValue));
        };
    
         fetchInitialOptions();
    }, []);


    const mapOptions = (data) => {
        return data.map(item.api.optionValue);
    };
    const [product, setProduct] = useState("");
    const selectRef = React.useRef();
    const [options, setOptions] = useState([]);
    const [input, setInput] = useState("");
    const [inputValue, setInputValue] = useState("");
    const debouncedSetInput = debounce((input) => {
        setInput(input);
    }, 300);

    const debouncedLoadOptions = debounce(async (inputValue, callBack) => {
        debouncedSetInput(inputValue);
    
        // Sanitize the input if necessary
        if (inputValue[0] === '"' || inputValue[0] === "'") {
            inputValue =
                inputValue.slice(0, 1) + inputValue.slice(1, inputValue.length).trim();
        }
        if (
            inputValue[inputValue.length - 1] === '"' ||
            inputValue[inputValue.length - 1] === "'"
        ) {
            inputValue =
                inputValue.slice(0, inputValue.length - 1).trim() +
                inputValue.slice(inputValue.length - 1, inputValue.length);
        }
    
        // Fetch products based on input or the default
        const response = await fetchProducts(
            item?.api?.query
                ? item.api.query.replace("{query}", inputValue)
                : `?term=${inputValue}&not_deleted=true`
        );
        
        const options = response.data.map(item.api.optionValue);
        setOptions(options);
        callBack(options);


        if (options.length === 1 && item?.resetValue) {
            const singleOption = options[0];
            handleChange(returnValue(singleOption), item.name, singleOption);
            bind(response.data[0], item.name);
            selectRef.current?.blur();
            selectRef.current?.focus();
        }
    }, 500);

    useEffect(() => {
        if (product) {
            setInputValue(product.label);
        }
    }, [product]);

    const [showValue, setShowValue] = React.useState(product);

    const customFilterOption = (option, inputValue) => {
        if (item?.api?.filter && item?.api?.filter?.includes(option?.value)) {
          return false;
        }  
        return true
      };
    return (
        <div className="dynamicForm">
            <style>
                {`
                    .dynamicForm .css-1f43avz-a11yText-A11yText,
                    .css-b62m3t-container {
                        width: 100%;
                    }
                    .dynamicForm .css-1y6o3d0-menu {width:100%}
                    .dynamicForm .css-166bipr-Input:after  .css-1cfo1cf::after {
                        visibility: visible !important;
                    }
                    .dynamicForm .css-1cfo1cf::after {
                        visibility: visible !important;
                    }

                    .dynamicForm .arbit-select__input-container:after  {
                        visibility: visible !important;
                    }


                `}
            </style>
            <AsyncSelect
                classNamePrefix="arbit-select"
                loadOptions={debouncedLoadOptions}
                defaultOptions={ options}
                filterOption={customFilterOption}
                placeholder={item.placeholder}
                cacheOptions
                ref={selectRef}
                isDisabled={item.disabled}
                value={showValue}
                onInputChange={(input) => {
                    setInputValue(input);
                }}
                onFocus={() => {
                    // when focus remove the singleValue component or hide it
                    if (!item?.features?.isMulti) {
                        setShowValue("");
                    }
                }}
                onBlur={() => {
                    // when blur remove the singleValue component or hide it
                    if (!item?.features?.isMulti) {
                        setShowValue(product);
                    } 
                    if (item?.resetValue) setShowValue("");
                }}
                isClearable={true}
                menuPortalTarget={!item?.noPortal ? document.body : null}
                onChange={(newData) => {
                    setShowValue(newData);
                    setProduct(newData);
                    handleChange(returnValue(newData), item.name, newData);
                    bind(
                        options.find((option) => option.id === newData.value),
                        item.name
                    );
                    if (item?.resetValue) setShowValue(""); 
                }}
                styles={style({
                    validation: validationMessage,
                    style: item.style || {},
                })}
                {...item.features}
            />
            {item?.createNew && (
                <div
                    className="text-blue-700 text-[14px] flex justify-end px-4 mt-2 font-normal leading-none w-full hover:text-blue-800 cursor-pointer"
                    onClick={() => {
                        window.open(
                            `/product/list#action=add&title=${formData?.title}`,
                            "_blank"
                        );
                    }}
                >
                    Didn’t find? Create new.
                </div>
            )}
        </div>
    );
};

export default CustomAsyncSelect;
