import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import Box from "../../../../dynamic-page/elements/Box";
import Icons from "../../../icons";
import DynamicForm from "../../../../dynamic-form";
import UI from "../../../../dynamic-form/elements";
import formView from "./model-view/form";
import prepareDataToUpdateRecipient from "../summary/util/prepareDataToUpdateRecipient";
import { apiActions, updateRow } from "../../../../../store/orders";
import notifier from "../../../../dynamic-page/util/notifier";
import { useDispatch } from "react-redux";

const RecipientTab = forwardRef((props, ref) => {
  const { data,  callBack, isView, id, update } = props;
  const dispatch = useDispatch();
  const [recipientForm, setRecipientForm] = useState(data?.recipient || null);
  const [recipientFormView, setRecipientFormView] = useState(formView(data?.recipient) || []);
  const formRef = useRef(null);
  const [submitState, setSubmitState] = useState(false);

  useImperativeHandle(ref, () => ({
    getData: async (validate) => {
      return getFormData(validate);
    },
    disableButton: () => {
      setSubmitState(true);
    },
    enableButton: () => {
      setSubmitState(false);
    },
  }));

  const getFormData = async (validate = true) => {
    const recipientData = await formRef?.current?.getData(true);
    const recipientValidation = await formRef?.current?.validate(validate);
    return { ...recipientData, _validation: recipientValidation };
  };

  const handleSubmit = async (state) => {
    const formData = await getFormData();

    if (isView) {
      ref?.current?.disableButton()
      if (!formData._validation.isValid) return
      const postData = await prepareDataToUpdateRecipient(formData);
      dispatch(
        apiActions({
          method: "POST",
          orderId: id.order[0],
          data: postData,
        })
      ).then((res) => {
        ref?.current?.enableButton()
        notifier(res.payload.data);
        if (res.payload.data.type === "success") {
          callBack(formData, state);
          
          update(res?.payload?.data?.data);
         
        }
      });
    } else {
      callBack(formData, state);
    }
  };


  return (
    <>
      <Box
        title="Recipient Address"
        className={'!h-[627px]'}
        options={
          recipientForm?.address_verified && (
            <div className="flex flex-row gap-[4px] items-center">
              <Icons.home className="w-[16px] h-[16px] text-green-500" />
              <span className="text-gray-700 text-[14px]">
                Address validated successfully
              </span>
            </div>
          )
        }
      >
        <div
          style={{ maxHeight: "calc(100vh - 280px)" }}
          className={"px-[16px] flex flex-col"}
        >
          <div
            className={
              "grid grid-cols-2 items-start gap-[12px] w-full justify-center pb-[22px]"
            }
          >
            <style>
              {`
              .css-1u9des2-indicatorSeparator { display: none; }
              .css-1xc3v61-indicatorContainer { padding-left: 0px; padding-right: 16px; }
              .css-1xc3v61-indicatorContainer svg { color: transparent !important; background: url('/assets/images/arrow.svg'); width: 13px; height: 13px; opacity: 0.7; }
            `}
            </style>
            {data?.summary && (
              <DynamicForm
                items={recipientFormView}
                style={{
                  label: {
                    className: "text-[16px] text-gray-700 font-semibold",
                  },
                  container: { className: "!gap-[8px]" },
                }}
                ref={formRef}
              />
            )}
          </div>
          <div className="flex w-full flex-row gap-[8px] justify-end py-[12px]">
            <UI.Button
              item={{
                className: "h-[52px] px-[24px] text-[16px]",
                buttonType: "primary",
                disabled: submitState,
              }}
              onClick={() => {
                handleSubmit({
                  action: isView ? "updateContent" : "save",
                });
              }}
            >
              Save
            </UI.Button>
          </div>
        </div>
      </Box>
    </>
  );
});

export default React.memo(RecipientTab);
