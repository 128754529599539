import React from "react";
import upperCaseString from "../../../dynamic-page/util/upperCaseString";
import util from "../../../dynamic-page/util";
import TransferType from "./transferType";

const orderId = ({ order, callBack, orderId, item, order_numbers }) => {
  return (
    <div className={"flex justify-start flex-col items-center gap-1.5 w-full"}>
      <div
        className={
          "flex flex-row gap-[4px] items-center justify-center text-gray-900 h-[1.5em] min-w-fit"
        }
      >
       <TransferType product={item?.type === 'product'} order={item?.type === 'order'} noType={item?.type === 'Undefined'} />
      </div>

      <div
        className={
          "min-w-max whitespace-no-wrap text-blue-600 text-[12px] hover:text-blue-700 cursor-pointer flex flex-row gap-[4px]"
        }
      >
        {order_numbers?.length > 1 && (
          <util.popUp
            toggle={() => (
              <div
                className={
                  "bg-blue-100 py-[1px] flex items-center  min-w-[20px] h-[20px] px-[6px] text-blue-800 rounded-full hover:bg-blue-200 transition-all"
                }
              >
                {order_numbers?.length}
              </div>
            )}
            action="click"
          >
            <div className={`flex flex-col gap-1 p-2  ${
                  order_numbers?.length > 10 &&
                  "overflow-y-scroll max-h-[230px]"
                } `}>
              {order_numbers?.map((order, index) => (
                <div
                  key={index}
                  className="flex justify-between items-center gap-2"
                >
                  <span className="text-gray-700 font-medium">{order}</span>
                  <div>
                    <util.copyText
                      className={"text-[12px]"}
                      text={order}
                      hint={false}
                    />
                  </div>
                </div>
              ))}
            </div>
          </util.popUp>
        )}
        <span
          onClick={() => {
            callBack({ action: "edit", data: item });
            //window.open(`/`, '_blank')
          }}
        >
          {order?.order_number}
        </span>
        <div>
          <util.copyText
            className={"text-[12px]"}
            text={order?.order_number}
            hint={false}
          />
        </div>
      </div>
    </div>
  );
};
export default orderId;
