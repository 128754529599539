import moment from "moment";
import React, { useEffect, useState } from "react";
import upperCaseString from "../../dynamic-page/util/upperCaseString";
import axios from "axios";

const StatusHistory = ({ item }) => {
  const [statuses, setStatuses] = useState([]);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  const lineAnimationStyles = {
    width: '50%',
    height: '100%',
    background: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(121,97,9,0.45475465576855745) 43%, rgba(0,212,255,1) 100%)',
    position: 'absolute',
    left: '0',
    animation: 'line-animation 0.5s linear infinite',
    borderRadius: "5px"
};
  // Fetch statuses when the component mounts
  useEffect(() => {
    setLoading(true);

    async function fetchStatuses() {
      try {
        const response = await axios.get(`/api/v1/orders/shipment-labels/${item?.id}/statuses`, {
          withCredentials: true,
        });
        const fetchedStatuses = response.data.data;
        setStatuses(fetchedStatuses);
        
        // Extract and set the status list from the response
        const statusList = statusLabel(fetchedStatuses);
        setList(statusList);
        setLoading(false);

      } catch (error) {
        console.error("Error fetching shipment statuses:", error);
      }
    }

    fetchStatuses();
  }, [item?.id]);

  // Function to process statuses and return a formatted list
  const statusLabel = (statuses) => {
    if (statuses) {
      return Object.values(statuses).map((status) => ({
        action: status?.detail,
        date: status?.timestamp,
        user: status?.created_by || "System",
        location: status?.location || "Warehouse",
      }));
    }
    return [];
  };

  return (
    <div className="flex flex-col gap-[6px] px-[4px] py-[2px]">
         {loading &&
                <div style={{
                    top: "15px",
                    left: "10px",
                    width: 'calc(100% - 20px)',
                    height: '5px',
                    backgroundColor: 'white',
                    position: 'absolute',
                    overflow: 'hidden',
                    borderRadius: "5px",
                    background: "#jjj"
                }}>
                    <div style={lineAnimationStyles}></div>
                </div>
            }
            <style> {`  @keyframes line-animation { 0% { left: -50%; } 100% { left: 100%; } } @keyframes new-animation { 0% {  background: rgba(164,202, 254, 0.3); } 90% { background: rgba(164,202, 254, 0.3); } 100% { background: white; } } .new-item { animation: new-animation 1.5s linear; }`} </style>
      <div className="w-full h-[18px] flex justify-between items-center gap-1.5">
        <div className="font-bold">Shipment Status History</div>
        <div className="text-center text-gray-900 text-sm font-medium leading-[21px]">
          {item?.current_status ? upperCaseString(item?.current_status, "word") : '-'}
        </div>
      </div>

      {/* Map through the list of statuses and render each status */}
      {list.map((statusItem, index) => (
        <div
          className="text-gray-900 text-xs font-normal leading-[18px] gap-[4px] flex flex-row items-center transition cursor-pointer hover:text-blue-700"
          key={index}
        >
          <span>{upperCaseString(statusItem.action, "word")}</span>
          {statusItem.user !== "System" && (
            <>
              <span className="text-gray-500 text-xs font-normal leading-[18px]"> by </span>
              <span>{upperCaseString(statusItem.user, "word")}</span>
            </>
          )}
          <span className="text-gray-500 text-xs font-normal leading-[18px]"> at </span>
          <span>{upperCaseString(statusItem?.location, "word")}</span>
          <span className="text-gray-500 text-xs font-normal leading-[18px]"> at </span>
          <span>{moment(statusItem?.date).format("MMM D, YYYY HH:mm")}</span>
        </div>
      ))}

      {/* Display additional information if available */}
      {item?.transit?.scheduled_delivery_date && (
        <div className="w-full flex justify-start items-center gap-1.5">
          <div className="text-right text-emerald-700 text-xs font-medium leading-[18px]">
            Scheduled Delivery Date: {moment(item?.transit?.scheduled_delivery_date).format("MMM D, YYYY HH:mm")}
          </div>
        </div>
      )}

      <div className="w-full h-[18px] flex justify-between items-center gap-1.5">
        <div className="text-right text-gray-500 text-xs font-medium leading-[18px]">
          Transit Time: {item?.transit_time} Days
        </div>
        <div className="text-right text-gray-500 text-xs font-medium leading-[18px]">
          Latest Event: {item?.latest_event ? moment(item?.latest_event?.timestamp).format("MMM D, YYYY HH:mm") : "-"}
        </div>
      </div>
    </div>
  );
};

export default StatusHistory;
