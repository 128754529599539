import { useEffect, useState } from "react";
import Icons from "../../../orders/icons";
import ArbitImageContainer from "../../../sub-elements/image-container";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";
import ArbitUploadButton from "../../../sub-elements/upload-button";
import { useDispatch, useSelector } from "react-redux";
import {
  addImageToProduct,
  addProductImages,
  addProductImagesLink,
  deleteImage,
  deleteProductImage,
  fetchAttributeGroups,
  fetchAttributes,
  fetchProduct,
  getAttributeTypes,
  makeMainImage,
} from "../../../../store/products";
import util from "../../../dynamic-page/util";
import axios from "axios";
import { selectMainImage } from "../../../../store/products/thunk";
import DirtyBox from "./DirtyBox";

const Media = ({ id }) => {
  const [isClicked, setClicked] = useState(false);

  const productData = useSelector((state) => state.productReducer.product);
  const productImageState = useSelector(
    (state) => state.productReducer.productImageState
  );

  const dispatch = useDispatch();

  const onCopyUrl = (url) => {
    copy(url);
    toast.info("Copied to clipboard", {
      toastId: "success1",
    });
  };

  useEffect(() => {
    if (productImageState === "pending") {
      toast.loading("Uploading images, please wait...", {
        toastId: "uploading",
      });
    } else {
      toast.dismiss("uploading");
    }
  }, [productImageState]);

  const onHandleLinkSrc = async (src) => {
    await dispatch(selectMainImage({ id, imageId: src?.id }))
      .unwrap()
      .then((response) => {
        if (response?.status === 204 || response?.status === 200) {
          dispatch(makeMainImage(src.url));
          util.notifier({
            type: response?.data?.type,
            message: response?.data?.message,
          });
        } else {
          util.notifier({
            type: response?.data?.type,
            message: response?.data?.message,
          });
        }
      })
      .catch((err) => {
        util.notifier(err);
      });
  };

  const onDelete2 = async (source) => {
    const imageId = source?.id;
    const url = source?.url;
    let temp = false;

    if (url === productData?.image) {
      temp = true;
    }

    await dispatch(deleteProductImage({ id, imageId }))
      .unwrap()
      .then((response) => {
        if (response?.status === 204 || response?.status === 200) {
          dispatch(deleteImage({ productData, imageId, temp }));
          util.notifier({
            type: "success",
            message: "Image has been deleted successfuly",
          });
        } else {
          util.notifier({
            type: response?.data?.type,
            message: response?.data?.message,
          });
        }
      })
      .catch((err) => {
        util.notifier(err);
      });
  };

  const renderPhotos = (sources) => {
    return sources?.map((source, index) => {
      return (
        <div className="flex w-[150px] h-[150px]  relative text-center  ">
          <img
            src={source.url}
            className="w-full h-auto object-cover rounded-xl"
          />
          <div
            onClick={() => onCopyUrl(source?.url)}
            className="absolute top-[8px] left-[16px]"
          >
            <Icons.link className="text-gray-300 hover:text-gray-500 w-4 h-4 cursor-pointer" />
          </div>
          {source?.url !== productData?.image ? (
            <div
              onClick={() => onHandleLinkSrc(source)}
              className="absolute bottom-[8px] left-[16px]"
            >
              <Icons.star className="text-gray-300 hover:text-gray-500 w-4 h-4 cursor-pointer" />
            </div>
          ) : (
            <div className="absolute bottom-[8px] left-[16px]">
              <Icons.star className="text-yellow-300 w-4 h-4 " />
            </div>
          )}
          <div className="absolute top-[8px] right-[16px]">
            <Icons.trashBin
              onClick={() => onDelete2(source)}
              className="text-gray-300 hover:text-gray-500 w-4 h-4 cursor-pointer"
            />
          </div>
        </div>
      );
    });
  };

  const handleFileChange = async (e) => {
    var files = e.target.files;
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("images" + "[" + [i] + "]", files[i]);
    }

    await dispatch(addProductImages({ id, formData }))
      .unwrap()
      .then((response) => {
        if (response?.status === 204 || response?.status === 200) {
          dispatch(addImageToProduct(response?.data?.data));
          util.notifier({
            type: response?.data?.type,
            message: response?.data?.message,
          });
        } else {
          util.notifier({
            type: response?.data?.type,
            message: response?.data?.message,
          });
        }
      })
      .catch((err) => {
        util.notifier(err);
      });
  };

  const [isUrlClicked, setUrlClicked] = useState(false);

  const handleHeaderFileChange = async (e) => {
    var files = e.target.files;

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("images[]", files[i]);
    }

    await dispatch(addProductImages({ id, formData }))
      .unwrap()
      .then((response) => {
        if (response?.status === 204 || response?.status === 200) {
          dispatch(fetchProduct({ id, productLanguage: "40" }));
          util.notifier({
            type: response?.data?.type,
            message: response?.data?.message,
          });
        } else {
          util.notifier({
            type: response?.data?.type,
            message: response?.data?.message,
          });
        }
      })
      .catch((err) => {
        util.notifier(err);
      });
  };

  return (
    <DirtyBox title="MEDIA">
      <style>
        {`
        .scroll-container {
          overflow-y: scroll;
          scrollbar-width: thin; 
          scrollbar-color: rgba(0, 0, 0, 0.5) transparent;
        }
        
        .scroll-container::-webkit-scrollbar {
          width: 8px; 
          height: 8px;
        }
        
        .scroll-container::-webkit-scrollbar-thumb {
          background: rgba(0, 0, 0, 0.5);
          border-radius: 10px;
        }
        
        .scroll-container::-webkit-scrollbar-track {
          background: transparent;
        }
        
        .scroll-container {
          -ms-overflow-style: none; 
          scrollbar-width: none;
        }
        
        .scroll-container::-webkit-scrollbar {
          display: none; 
        }
        `}
      </style>
      {!isClicked && (
        <div className="flex flex-col flex-wrap scroll-container max-h-[320px] overflow-y-auto">
          <div className="flex flex-wrap   pt-[8px]  gap-2 ">
            {
              <ArbitUploadButton
                setUrlClicked={setUrlClicked}
                isUrlClicked={isUrlClicked}
                onClose={() => {
                  setUrlClicked(false);
                }}
                id={id}
                onFile={handleFileChange}
                inputId={"drop-zone2"}
                multipleImage={true}
              />
            }
            {renderPhotos(productData?.images)}
          </div>
        </div>
      )}
    </DirtyBox>
  );
};
export default Media;
