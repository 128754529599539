import React from "react";
import DynamicForm from "../../../../../../dynamic-form";

function SummaryWeight({value, callBack, onlyNumber, notNullable, text}) {
    const formItems = [
        {
            name: "bb_weight",
            editableText : text,
            reverse: true,
            type: "OrderText",
            defaultValue: value || "-",
            clear: false,
            reset: false,
            notNullable: notNullable,
            onlyNumber: onlyNumber,
            className: " gap-[0px]",
            disabled: false,
            innerClassName: "!text-[16px] !text-gray-900 leading-[14px]",
        },
    ];

    const ref = React.useRef(null);
    return (
        <DynamicForm
            items={formItems}
            ref={ref}
            style={{
                label: {className: "w-full  !text-gray-400 text-14px"},
                container: {className: "gap-[0px]"},
            }}
            onChange={(data) => {
                if (data) {
                    callBack(data?.bb_weight)
                }
            }}
        />
    );
}

export default SummaryWeight;
