import React from "react";
import util from "../../../dynamic-page/util";
import Icons from "../../icons";
import DynamicForm from "../../../dynamic-form";
import {
  apiActions,
  fetchSingleOrder,
  updateRow,
} from "../../../../store/orders";
import { useDispatch } from "react-redux";
import connectProductFrom from "../../../dynamic-page/components/connectProductForm";
import ConnectProductModal from "../../../dynamic-page/components/ConnectForm";
import { OrderItem } from "../../models-view/forms";
import { HiFilter } from "react-icons/hi";
import descriptiveContent from "../../../../utils/descriptiveContent";

const mapListings = (item) => ({
  id: item?.first_item?.id,
  identifier: item?.first_item?.item_sku,
  url: item?.sales_channel_listing?.url,
  label: item?.first_item?.item_title,
  channel: item?.sales_channel_account?.channel?.name,
  brand: item?.sales_channel_account?.name,
  image: item?.first_item?.image_url,
});

const Item = ({ product, orderId, order, onFilterApply , callBack}) => {
  const productList = order?.first_item;
  const multiple = order?.items_count > 1;
  const dispatch = useDispatch();

  return (
    <div
      className={"w-full gap-[6px] flex flex-col py-2 justify-start  h-[64px]"}
    >
      <div className={"flex flex-row gap-[8px] !h-[34px] justify-between"}>
        <util.truncateText
          maxLines={1}
          className={"!text-[14px] !leading-[21px] mt-1"}
          tooltip={true}
        >
          {productList?.item_title}
        </util.truncateText>
      </div>

      <div className={"flex justify-between"}>
        {multiple ? (
          <div
            className={
              "text-pink-700 flex flex-row gap-[4px] font-medium items-center"
            }
          >
            <Icons.union className={"h-[12px]"} /> Multiple SKU
          </div>
        ) : (
          <div
            className={
              "flex flex-row items-center gap-[4px] text-gray-500 whitespace-nowrap"
            }
          >
            <util.popUp
              toggle={(selected) => (
                <div className="text-[12px] leading-[18px] font-medium">
                  {productList?.item_sku}
                </div>
              )}
              action={"hover"}
              // leftSpace={style?.leftSpace}
            >
              <div className={"min-w-max px-[8px] py-[4px]"}>
                SKU
              </div>
            </util.popUp>
            <util.copyText
              className={"text-[12px]"}
              text={productList?.item_sku}
              hint={false}
            />
            {descriptiveContent(
              <HiFilter
                onClick={(e) => {
                  e.stopPropagation();
                  onFilterApply(
                    {
                      item_sku: order?.first_item?.item_sku,
                    },
                    null
                  );
                }}
                className={` w-3 h-3 cursor-pointer text-gray-300 hover:text-gray-500`}
              />,
              "Filter by item SKU"
            )}
          </div>
        )}
        {multiple ? (
          <div
            className={
              "text-pink-700 text-xs font-medium flex flex-row gap-[4px] items-center"
            }
          >
            {order?.items_count} Items
          </div>
        ) : productList?.brand ? (
          <div className="flex items-center gap-[2px] !text-[12px] whitespace-nowrap">
            <span
              className={`w-[18px] h-[18px] rounded-full  flex justify-center items-center text-xs font-medium ${
                productList?.product_type === 1
                  ? "text-teal-700 bg-teal-100"
                  : "text-indigo-700 bg-indigo-100"
              } p-[0px]`}
            >
              {productList?.product_type === 1 ? "S" : "G"}
            </span>
            <div
              className={
                "text-blue-700 hover:text-blue-800 cursor-pointer flex flex-row gap-[4px] whitespace-nowrap"
              }
              onClick={() => {
                if(order?.first_item?.brand){
                  window.open(
                    `/product/${productList?.product_id}`,
                    "_blank"
                  );
                }
                
              }}
            >
              {productList?.brand?.assigned ? (
                <>
                  <img
                    src={
                      productList?.brand?.assigned
                        ?.image_url || "/assets/images/defaultAvatar.png"
                    }
                    className={"w-[18px] h-[18px] rounded-full object-cover"}
                  />
                  {util.upperCaseString(
                    productList?.brand?.assigned?.name,
                    "word"
                  )}
                </>
              ) : (
                <div className="text-gray-400">Unassigned</div>
              )}
            </div>

            {productList?.brand?.assigned && descriptiveContent(
              <HiFilter
                onClick={(e) => {
                  e.stopPropagation();
                  onFilterApply(
                    {
                      assigned_user: [
                        order?.first_item?.brand?.assigned
                          ?.id,
                      ],
                    },
                    null,
                    {
                      assigned_user: [
                        {
                          label:
                            order?.first_item?.brand?.assigned
                              ?.name,
                          value:
                            order?.first_item?.brand?.assigned
                              ?.id,
                        },
                      ],
                    }
                  );
                }}
                className={` w-3 h-3 cursor-pointer text-gray-300 hover:text-gray-500`}
              />,
              "Filter by Assigned User"
            )}
          </div>
        ) : (
          <div className="flex items-center gap-[2px] !text-[12px]">
            <ConnectProductModal
              key={orderId}
              toggle={() => (
                <div className={"flex flex-row gap-[2px]"}>
                  <span className="w-[18px] h-[18px] rounded-full bg-red-100 flex justify-center items-center text-xs font-medium text-red-800 p-[0px]">
                    <Icons.link className={"w-[11px] h-[11px]"} />
                  </span>
                  <div
                    className={
                      "text-red-700 hover:text-red-800 cursor-pointer whitespace-nowrap"
                    }
                  >
                    Not Connected
                  </div>
                </div>
              )}
              callBack={(data) => {
                const product_id = data?.id;
                const connectionAction = "connect-product";
                return dispatch(
                  apiActions({
                    data: {
                      product_id: product_id,
                      sku: productList?.item_sku,
                    },
                    action: connectionAction,
                    orderId: `${orderId}/${productList?.id}`,
                  })
                ).then((result) => {
                  if (result?.payload?.data?.type === "success") {
                    util.notifier({
                      type: 'success',
                      message: 'Product connected successfully',
                    })
                    callBack(order, 'red');
                    return true;
                  }
                });
              }}
              mapListings={mapListings}
              noAddMore={true}
              defaultListing2={order}
              salesChannelItem={OrderItem}
              newDesign={true}
              urlParams={{
                title: productList?.item_title,
                image: productList?.image || productList?.item_image,
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Item;
