import ArbitProductPage from "./debt/ArbitProductPage";

import SearchInput from "./debt/searchInput";
import TabButton from "./elements/TabButton";
import DynamicTable from "./index";

import FooterPagination from "./elements/FooterPagination";
import React, { useEffect, useState } from "react";

import DynamicModal from "./util/dynamicModalForm";
import getActions from "./util/getActions";
import ConfirmModal from "./util/confirmModal";
import { useDispatch, useSelector } from "react-redux";
import { updateRow } from "../../store/dynamic-page";

const DynamicPage = ({
  pageData,
  pageTitle,
  control,
  modelsView,
  refreshPage,
}) => {
  const user = useSelector((state) => state.authUser.authUser);

  let params = { form: {}, actions: {} };
  if (modelsView?.filter) {
    const filterCount = pageData?.filters?.filters // the ones that are not null  or undefined
      ? Object.values(pageData?.filters?.filters)?.filter(
          (item) => item !== null && item !== undefined
        )?.length
      : 0;
    const tabFiltersCount = modelsView?.tabs
      ? modelsView?.tabs()?.[pageData?.filters?.tab]?.filter
        ? Object.values(modelsView?.tabs()?.[pageData?.filters?.tab]?.filter)
            ?.length
        : 0
      : 0;
    params.filter = {
      items: modelsView.filter(
        pageData?.filters?.filters,
        pageData?.filtersMeta
      ),
      callback: (rs, bound) => {
        control?.onFilterApply(rs, bound);
      },
      count: filterCount - tabFiltersCount,
      filters: pageData?.boundFilters,
      pageFilters: pageData?.filters?.filters,
      discard: control?.pageSettings?.ignoredFilters,
    };
  }

  if (modelsView?.sort) {
    params.sort = {
      items: modelsView?.sort({
        callback: (value) => {
          control?.onSortApply(value);
        },
      }),
      selected: pageData?.filters?.sort,
    };
  }

  const [visibleExternalModal, setVisibleExternalModal] = useState(null);

  if (modelsView?.forms) {
    Object.keys(modelsView?.forms).map((item, index) => {
      params.form[item] = {
        open: (data, component) => {
          if (pageData?.loading === "idle") {
            if (component) {
              setVisibleExternalModal(component);
            } else {
              setVisibleForm(modelsView?.forms[item](data));
            }
            setVisibleFormData(data);
          }
        },
      };
    });
  }

  const [refreshing, setRefreshing] = useState(false);

  //const pageData = useSelector((state) => state[pageDataPath]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [visibleForm, setVisibleForm] = useState(null);
  const [visibleFormData, setVisibleFormData] = useState(null);
  const [confirmationModalVisibility, setConfirmationModalVisibility] =
    useState(null);

  if (modelsView?.headerOption) {
    params.headerOption = modelsView?.headerOption.map((item, index) => {
      return {
        view: item?.view({
          callBack: modelsView?.actions?.[item.action]?.onClick,
          forms: params?.form,
          actions: modelsView?.actions,
          data: item?.data,
          refreshing: refreshing,
          setRefreshing: setRefreshing,
          selectedRow,
          setSelectedRow,
          refreshPage: refreshPage,
          dispatch: control?.dispatch,
        }),
      };
    });
  }
  if (modelsView?.detailsOptions) {
    params.detailsOptions = modelsView?.detailsOptions.map((item, index) => {
      return {
        view: item?.view({
          callBack: modelsView?.actions?.[item.action]?.onClick,
          forms: params?.form,
          actions: modelsView?.actions,
          data: item?.data,
          refreshing: refreshing,
          setRefreshing: setRefreshing,
          selectedRow,
          setSelectedRow,
          refreshPage: refreshPage,
          dispatch: control?.dispatch,
          setVisibleExternalModal,
          extra: control?.pageSettings?.extra,
        }),
      };
    });
  }

  Object.keys(modelsView?.actions).map((item, index) => {
    params.actions[item] = {
      ...modelsView?.actions[item],
      onClick: (data) => {
        modelsView?.actions[item].onClick({
          forms: params?.form,
          actions: params?.actions,
          data: data?.id ? data : selectedRow,
          refreshPage: refreshPage,
          setVisibleExternalModal,
          setConfirmationModalVisibility,
          extra: control?.pageSettings?.extra,
          removeRow: handleRemoveItemFromList,
          replaceRow: control?.replaceRow,
        });
      },
    };
  });

  const actionList = (row) => {
    return getActions(row, params?.actions, pageData);
  };

  

  const onSelectRow = (Rows) => {
    setSelectedRow(Rows);
  };
  const readAnchorData = () => {
    const anchor = window.location.hash.substr(1);
    const result = { action: "", data: {} };

    if (anchor) {
      const params = anchor.split("&");
      try {
        params.forEach((param) => {
          const [key, value] = param.split("=");
          if (key === "action") {
            result.action = decodeURIComponent(value);
          } else if (key === "title") {
            // Decode the 'title' parameter while preserving percent signs
            result.data[key] = decodeURIComponent(
              value.replace(/%%/g, "%25 %")
            );
          } else {
            result.data[key] = decodeURIComponent(value);
          }
        });
        return result;
      } catch (error) {
        // Handle the error, e.g., log it or show an error message
        console.error("Error decoding URI component:", error);
        return null;
      }
    } else {
      return null;
    }
  };

  useEffect(() => {
    const anchor = readAnchorData();
    if (anchor && modelsView?.actions[anchor.action]) {
      modelsView?.actions[anchor.action]?.onClick({
        data: anchor.data || {},
        forms: params?.form || [],
        actions: params?.actions || {},
        refreshPage: refreshPage || (() => true),
      });
    }
  }, []);

  const handleTableAction = (action, data) => {
    modelsView?.actions?.[action]?.onClick({
      data: data,
      forms: params?.form,
      actions: params?.actions,
      refreshPage: refreshPage,
      setVisibleExternalModal,
      setConfirmationModalVisibility,
      extra: control?.pageSettings?.extra,
      removeRow: handleRemoveItemFromList,
      replaceRow: control?.replaceRow,
    });
  };

  const giveSelectedItemId = (item) => {
    const ID = item
      ? item?.id
        ? [item?.id]
        : item
      : selectedRow.map((item) => item.id);
    // setSelectedRow([])
    return ID;
  };
  const dispatch = useDispatch();
  const handleRemoveItemFromList = (id) => {
    const ids = giveSelectedItemId(id);
    ids.forEach((item) => {
      control.replaceRow(item);
      setTimeout(() => {
        dispatch(updateRow({ id: item, path: `_deletedRow`, value: true }));
        setTimeout(() => {
          dispatch(updateRow({ id: item }));
        }, 1000);
      }, 5000);
    });
  };

  const PageTitle = control?.pageSettings?.newDesign
    ? ArbitProductPage.TitleNew
    : ArbitProductPage.Title;

  return (
    <>
      <ArbitProductPage.Page className="flex flex-col ">
        {!control?.pageSettings?.product?.hideHeaddings && (
          <PageTitle
            onSearch={
              control?.pageSettings?.newDesign ? control?.onSearchApply : null
            }
            loading={pageData?.loading}
            allowExport={control?.pageSettings?.allowExport?.(pageData)}
            exported={ control?.pageSettings?.exports?.(pageData, selectedRow )}
            title={pageTitle}
            sort={params?.sort}
            filter={params?.filter}
            options={params?.options}
            actionList={actionList(selectedRow)}
            selectedRow={selectedRow}
            headerOption={params?.headerOption}
          />
        )}

        {!control?.pageSettings?.product?.hideHeaddings &&
          !control?.pageSettings?.newDesign && (
            <div
              className={
                "flex flex-row justify-between px-[16px] py-[4px] items-center"
              }
            >
              <SearchInput
                className="w-[362px]"
                placeholder="Search..."
                onSubmit={control?.onSearchApply}
                defaultValue={pageData?.filters?.search}
              />
              {modelsView.tabs && (
                <TabButton
                  loading={pageData?.filterLoading}
                  callBack={control.onTabApply}
                  active={pageData?.filters?.tab}
                  list={Object.keys(modelsView?.tabs(pageData))?.map((key) => {
                    const tab = modelsView?.tabs(pageData)?.[key];
                    return {
                      id: key,
                      name: tab?.label,
                      count: tab?.count,
                    };
                  })}
                />
              )}
            </div>
          )}

        {params?.detailsOptions && params?.detailsOptions?.length > 0 && (
          <div className="relative flex justify-end">
            <div className="flex justify-end pr-4 pb-2 w-fit self-end absolute -top-[45px]">
              {params?.detailsOptions &&
                params?.detailsOptions?.length > 0 &&
                params?.detailsOptions.map((option, index) => {
                  return (
                    <div
                      className={"relative "}
                      style={{ order: option.order }}
                      key={index}
                    >
                      {option.view}
                    </div>
                  );
                })}
            </div>
          </div>
        )}

        <ArbitProductPage.Content className="content">
          {!control?.pageSettings?.product?.product_id &&
            (control?.pageSettings?.mediaQuery ? (
              control?.pageSettings?.mediaQuery
            ) : (
              <style>
                {`
                             @media (768px <= width <= 900px) {
                                .content .contt {
                                    height: calc(100vh - 240px) !important;
                                }
                            }
                            @media (max-width: 768px) {
                                .content .contt {
                                    height: calc(100vh - 324px) !important;
                                }
                            }
                    `}
              </style>
            ))}
          <div className=" ">
            <div className="overflow-x-auto max-w-[100%]">
              <DynamicTable
                mini={control?.pageSettings?.product?.product_id ? true : false}
                view={modelsView?.table}
                data={modelsView?.table(
                  pageData?.rows,
                  // add the ability to open the actions from the table
                  handleTableAction,
                  control?.pageSettings?.tableProps
                    ? control?.pageSettings?.tableProps
                    : {},
                  typeof control?.pageSettings?.hideSelect === "function"
                    ? control?.pageSettings?.hideSelect(pageData)
                    : control?.pageSettings?.hideSelect || false
                )}
                actions={actionList}
                onSelect={onSelectRow}
                selectedRow={selectedRow}
                loading={pageData?.loading}
                append={control?.loading}
                loadMoreData={control?.loadMoreData}
                meta={pageData?.meta}
                ver={2}
                style={{
                  table: {
                    style: { width: "100%", minWidth: "100%" },
                  },
                  header: {
                    className: "justify-center !px-[16px]",
                    supply: { className: "hidden" },
                    image: {
                      className: `!px-[0px] `,
                      th: `${
                        control?.pageSettings?.product?.hideProduct && "hidden"
                      }`,
                    },
                    product: {
                      th: ` ${
                        control?.pageSettings?.product?.hideProduct && "hidden"
                      }`,
                    },
                    ...control?.pageSettings?.headerStyles,
                  },
                  row: {
                    className:
                      " !px-[8px] !py-[0px] text-[14px] !items-center justify-center flex ",
                    image: {
                      className: `!py-[0px] w-[64px] justify-center !px-[0px] `,
                      td: `!py-[0px] w-[64px] ${
                        control?.pageSettings?.product?.hideProduct && "hidden"
                      }`,
                    },
                    _actions: { className: "!py-[0px]" },
                    supply: {
                      td:
                        pageData?.filters?.filters?.summaryType !== "resupply"
                          ? "hidden"
                          : "",
                    },
                    note: { className: "!py-[10px]" },
                    product: {
                      className: `!justify-center w-[100%]  !min-w-[300px] ${
                        control?.pageSettings?.product?.hideProduct && "hidden"
                      }`,
                      //td : "!max-w-[446px]"
                      td: `${
                        control?.pageSettings?.product?.hideProduct && "hidden"
                      }`,
                    },
                    "exp.date": { className: "!pr-[16px]" },
                    ...control?.pageSettings?.rowStyles,
                  },
                  hiddenActions:
                    typeof control?.pageSettings?.hiddenActions === "function"
                      ? control?.pageSettings?.hiddenActions(pageData)
                      : control?.pageSettings?.hiddenActions || false,
                  hideSelect:
                    typeof control?.pageSettings?.hideSelect === "function"
                      ? control?.pageSettings?.hideSelect(pageData)
                      : control?.pageSettings?.hideSelect || false,
                }}
              />
            </div>
          </div>
        </ArbitProductPage.Content>

        <FooterPagination
          meta={{ ...pageData?.meta, limit: pageData?.filters?.limit}}
          pageData={pageData}
          loading={pageData?.loading}
          onLimit={control?.onHandleLimit}
        />
      </ArbitProductPage.Page>
      {visibleForm && (
        <DynamicModal
          title={visibleForm?.title}
          formModel={visibleForm}
          formView={visibleForm?.viewModel}
          data={visibleFormData}
          submit={visibleForm?.submit}
          actions={params?.actions}
          createAnother={
            visibleFormData?.id
              ? false
              : control?.pageSettings?.createAnother
              ? true
              : false
          }
          onClose={() => {
            setVisibleForm(null);
            setVisibleFormData(null);
          }}
          onSubmit={(actionName, data, callBack, clear) => {
            modelsView?.actions?.[actionName]?.onClick({
              data: data,
              callBack: callBack,
              close: () => {
                setVisibleForm(null);
                setVisibleFormData(null);
              },
              clear: () => {
                clear();
                setVisibleFormData(null);
              },
              forms: params?.form,
              actions: params?.actions,
              refreshPage: refreshPage,
              user: user,
              setVisibleExternalModal,
              replaceRow: control?.replaceRow,
            });
          }}
          container={(child) => (
            <>
              <div
                className={`max-w-[650px] grid ${
                  visibleForm?.containerStyle && visibleForm?.containerStyle
                } grid-cols-2 items-start gap-y-3 gap-x-2 w-full justify-center  border-t border-t-gray-200 pt-[4px]`}
              >
                {child}
              </div>
            </>
          )}
          cancel={visibleForm?.cancel}
          sourceData={[]}
        />
      )}
      {visibleExternalModal}
      {confirmationModalVisibility && (
        <ConfirmModal
          show={confirmationModalVisibility}
          infoText={
            confirmationModalVisibility?.infoText ||
            "Are you sure you want to mark this order as shipped?"
          }
          onClose={() => setConfirmationModalVisibility(false)}
          confirmText={
            confirmationModalVisibility?.confirmText || "Yes, Mark as Shipped"
          }
          confirmColor="green"
          cancelText="Cancel"
          cancelColor="red"
          styles={{
            confirmButton: "!bg-green-50 ",
            cancelButton: "!bg-red-50 ",
            infoText: "!font-medium",
          }}
          onConfirm={(setSubmit) => {
            confirmationModalVisibility?.callBack(setSubmit);
          }}
        />
      )}
    </>
  );
};

export default DynamicPage;
