/**
 *
 */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ArbitButton from "../sub-elements/button";
import Modal from "../sub-elements/modal";
import ArbitNormalInput from "../sub-elements/normal-input";
import ErrorComponent from "../sub-elements/ErrorComponent";
import { toast } from "react-toastify";

import { fetchAttributes, fetchProduct, UpdateAttribute } from "../../store/products";
import validationHelper from "./validation-helper";
import Dropdown from "../sub-elements/dropdown";
import CreatableSelect from "react-select/creatable";
import { HiInformationCircle } from "react-icons/hi";

const ArbitNewAttributeModal = ({
  show,
  onClose,
  attributesData,
  attributeGroups,
  attributeTypes,
  createNewAttribute,
  id,
  attributeLocale,
  productLanguage,
}) => {
  const dispatch = useDispatch();
  const [searchResult, setSearchResult] = useState([]);
  const searchData = Array.from(attributesData, (x) => x.name);
  const [newAttribute, setNewAttribute] = useState("");
  const [attributeValue, setAttributeValue] = useState("");
  const [attributeGroup, setAttributeGroup] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    dispatch(fetchAttributes());
  }, [dispatch]);


  let attribute;

  if (newAttribute !== "") {
    attribute = attributesData.find((item) => item.name === newAttribute);
  }

  const validationRule = attribute?.validation_rules;
  const attributeId = attribute?.id;

  const [errMessage, setErrorMessage] = useState("");
  const [valid, setValid] = useState(true);
  const onSearchChange = (value) => {
    setSearchResult(
      value === ""
        ? []
        : searchData.filter((item) =>
            item.toLowerCase().startsWith(value.toLowerCase())
          )
    );
  };

  const setAttributeGroupp = (group) => {
    setAttributeGroup(group);
    setAttributeValue("");
    setValid(true);
    setErrorMessage("");
  };

  // render different input based on attribute type
  const renderInput = () => {
    if (attributeGroup?.type === "TEXT") {
      return (
        <ArbitNormalInput
          label="Value"
          value={attributeValue}
          onChange={(e) => setAttributeValue(e.target.value)}
          placeholder="Enter Attribute Value"
          type="text"
          error={!valid}
          helperText={errMessage}
        />
      );
    } else if (attributeGroup?.type === "NUMBER") {
      return (
        <ArbitNormalInput
          label="Value"
          value={attributeValue}
          onChange={(e) => setAttributeValue(e.target.value)}
          placeholder="Enter Attribute Value"
          type="number"
          error={!valid}
          helperText={errMessage}
        />
      );
    } else if (attributeGroup?.type === "BOOLEAN") {
      return (
        <ArbitNormalInput
          label="Value"
          value={attributeValue}
          onChange={(e) => setAttributeValue(e.target.value)}
          placeholder="Enter Attribute Value"
          type="boolean"
          error={!valid}
          helperText={errMessage}
        />
      );
    } else if (attributeGroup?.type === "DATETIME") {
      return (
        // data picker
        <ArbitNormalInput
          label="Value"
          value={attributeValue}
          onChange={(e) => setAttributeValue(e.target.value)}
          placeholder="Enter Attribute Value"
          type="datetime-local"
          error={!valid}
          helperText={errMessage}
        />
      );
    } else if (attributeGroup?.type === "DIMENSION") {
      return (
        <ArbitNormalInput
          label="Value"
          value={attributeValue}
          onChange={(e) => setAttributeValue(e.target.value)}
          placeholder="Enter Attribute Value"
          type="dimension"
          error={!valid}
          helperText={errMessage}
        />
      );
    } else if (attributeGroup?.type === "FLOAT") {
      return (
        <ArbitNormalInput
          label="Value"
          value={attributeValue}
          onChange={(e) => setAttributeValue(e.target.value)}
          placeholder="Enter Attribute Value"
          type="float"
          error={!valid}
          helperText={errMessage}
        />
      );
    } else if (attributeGroup?.type === "INTEGER") {
      return (
        <ArbitNormalInput
          label="Value"
          value={attributeValue}
          onChange={(e) => setAttributeValue(e.target.value)}
          placeholder="Enter Attribute Value"
          type="integer"
          error={!valid}
          helperText={errMessage}
        />
      );
    } else if (attributeGroup?.type === "DROPDOWN") {
      return (
        <Dropdown
          label="Value"
          value={attributeValue || ""}
          items={attributeGroup?.validation_rules?.enum}
          onChange={(e) => setAttributeValue(e.target.value)}
          placeholder="Enter Attribute Value"
          type="dropdown"
          error={!valid}
          helperText={errMessage}
        />
      );
    } else {
      return (
        <ArbitNormalInput
          label="Value"
          value={attributeValue}
          onChange={(e) => setAttributeValue(e.target.value)}
          placeholder="Enter Attribute Value"
          type="text"
          error={!valid}
          helperText={errMessage}
        />
      );
    }
  };

  const handleFileInputChange = (e) => {
    setAttributeValue(e.target.files[0]);
  };

  const attributeOptions = Array.isArray(attributesData) ? attributesData.map((item) => {
    return {
      value: item.id,
      label: item.name,
      type: item.type,
      validation_rules: item.validation_rules,
      description: item.description,
    };
  }).filter(item => ![22, 23, 20, 21, 39, 15,73,3,4,5,6,13,26,7, 75, 29,1,74,47].includes(item.value)) : []


  const AddAttribute = async () => {
    if (attributeValue === "" || !attributeGroup?.value) {
      setValid(false);
      setErrorMessage("Please enter a valid Attribute and  Value");
    } else {
      const response = await validationHelper(
        validationRule,
        attributeValue,
        newAttribute
      );
      let payload = {
        value: attributeValue,
        locale: attributeLocale,
      };
      if (response === true) {
        setValid(true);
        setErrorMessage("");
        await dispatch(
          UpdateAttribute({
            id,
            attributeId: attributeGroup?.value,
            payload,
          })
        )
          .unwrap()
          .then((response) => {
            if (response?.status === 200) {
              onClose();
              dispatch(fetchProduct({ id, productLanguage }));
              toast.success(
                response?.data?.message || "Successfully Update the Attribute",
                {
                  toastId: "success1",
                }
              );
            } else {
              toast.error(response?.data?.message || "Error ", {
                toastId: "success1",
              });
            }
          })
          .catch((err) => {
            toast.error("Error ", {
              toastId: "success1",
            });
          });
      } else {
        setValid(false);
        setErrorMessage(response);
      }
    }
  };

  const [tooltip, setTooltip] = useState("");

  return (
    <>
      <Modal
        title="Add New Attribute to The Product"
        show={show}
        onClose={onClose}
        center={true}
      >
        <div className="flex flex-col h-fit w-[60vw]">
          <div className="flex gap-[8px] w-full items-center m-0">
            <div className="flex flex-col items-start gap-2 w-[49%]">
              <div className="flex items-center w-full">
                <label className="text-sm font-medium text-gray-700">
                  Attribute
                </label>
                <HiInformationCircle
                  className="text-[14px] font-[500] ml-2 text-gray-300"
                  data-tip={tooltip}
                />
              </div>
              {/* <SearchInput
              className=""
              hide_submit
              searchLabel={"Attributes"}
              searchResult={searchResult}
              setNewAttribute={(value) => {
                setNewAttribute(value)
                setAttributeGroup(attributesData.find(item => item?.name === value))
              }}
              // value={searchResult}
              keywords={true}
              onChange={
                (value) => onSearchChange(value)
              }
              createNewAttribute={createNewAttribute}
            /> */}
              <CreatableSelect
                options={attributeOptions}
                placeholder={"Search Attribute"}
                defaultOptions
                cacheOptions
                value={attributeGroup}
                formatCreateLabel={(inputValue) => (
                  <div className="cursor-pointer">
                    <span className="text-blue-700 font-medium ">
                      + Add New:{" "}
                    </span>
                    <span>{inputValue}</span>
                  </div>
                )}
                isValidNewOption={(inputValue) => inputValue.trim().length > 0}
                onCreateOption={(inputValue) => {
                  createNewAttribute();
                }}
                onChange={(e) => {
                  setAttributeGroupp(e);
                  setNewAttribute(
                    attributesData.find((item) => item?.name === e?.label)?.name
                  );
                  setAttributeValue("");
                  setTooltip(e.description);
                }}
                optionHeight={50}
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    height: 42,
                    // minHeight: 50,
                    // width: 300,
                    borderRadius: 10,
                    background: "#f3f4f6",
                    border: state.isFocused
                      ? "1px solid #00A3FF"
                      : "1px solid #E5E5E5",
                    // border: (brand === '' && submit) && '2px solid red',
                    boxShadow: state.isFocused
                      ? "0px 0px 0px 1px #00A3FF"
                      : "none",
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    // borderRadius: 10,
                    backgroundColor: state.isSelected ? "#00A3FF" : "white",
                    color: state.isSelected ? "white" : "black",
                    "&:hover": {
                      backgroundColor: "#00A3FF",
                      color: "white",
                    },
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    borderRadius: 10,
                    overflow: "hidden",
                    border: state.isFocused
                      ? "1px solid #00A3FF"
                      : "1px solid #E5E5E5",
                    boxShadow: state.isFocused
                      ? "0px 0px 0px 1px #00A3FF"
                      : "none",
                    "&:hover": {
                      border: state.isFocused
                        ? "1px solid #00A3FF"
                        : "1px solid #E5E5E5",
                    },
                  }),
                  container: (provided, state) => ({
                    ...provided,
                    width: "100%",
                  }),
                }}
              />
            </div>
            <div className="flex flex-col items-start gap-2 w-[49%]">
              <label className="text-sm font-medium text-gray-700">
                Attribute Value
              </label>
              {attributeGroup?.type === "DROPDOWN" ? (
                <Dropdown
                  placeholder="Attribute Value"
                  bg_color={"gray-50"}
                  // justify={'evenly'}
                  border_color={"gray"}
                  text_color={"gray"}
                  title={"Attribute Type"}
                  value={attributeValue || ""}
                  items={attributeGroup?.validation_rules?.enum}
                  onSelect={(e) => setAttributeValue(e)}
                  type="dropdown"
                  width={"100%"}
                />
              ) : attributeGroup?.type === "BOOLEAN" ? (
                //date picker
                <Dropdown
                  placeholder="Value"
                  bg_color={"gray-50"}
                  // justify={'evenly'}
                  border_color={"gray"}
                  text_color={"gray"}
                  title={"Attribute Type"}
                  value={
                    attributeValue === true
                      ? "TRUE"
                      : attributeValue === false
                      ? "FALSE"
                      : ""
                  }
                  items={["TRUE", "FALSE"]}
                  onSelect={(e) => {
                    if (e === "TRUE") {
                      setAttributeValue(true);
                    }
                    if (e === "FALSE") {
                      setAttributeValue(false);
                    }
                  }}
                  type="dropdown"
                  width="100%"
                />
              ) : attributeGroup?.type === "DATETIME" ? (
                //date time picker
                <ArbitNormalInput
                  label="Value"
                  value={attributeValue}
                  onChange={(e) => setAttributeValue(e)}
                  placeholder="Enter Attribute Value"
                  type="datetime-local"
                  error={!valid}
                  helperText={errMessage}
                  width={"full"}
                />
              ) : attributeGroup?.type === "TIME" ? (
                //time picker
                <ArbitNormalInput
                  label="Value"
                  value={attributeValue}
                  onChange={setAttributeValue}
                  placeholder="Enter Attribute Value"
                  type="time"
                  error={!valid}
                  helperText={errMessage}
                  width={"full"}
                />
              ) : attributeGroup?.type === "INTEGER" ||
                attributeGroup?.type === "FLOAT" ? (
                //number picker
                <ArbitNormalInput
                  label="Value"
                  value={attributeValue}
                  onChange={(e) => setAttributeValue(e)}
                  placeholder={
                    attributeGroup?.type === "INTEGER"
                      ? "E.G 1,2,3,4"
                      : attributeGroup?.type === "FLOAT"
                      ? "E.G 0.25, 0.62, 1.5"
                      : "Enter Attribute Value"
                  }
                  type="number"
                  error={!valid}
                  helperText={errMessage}
                  width={"full"}
                />
              ) : attributeGroup?.type === "DATE" ? (
                //date picker
                <ArbitNormalInput
                  label="Value"
                  value={attributeValue}
                  onChange={setAttributeValue}
                  placeholder="Enter Attribute Value"
                  type="date"
                  error={!valid}
                  helperText={errMessage}
                  width={"full"}
                />
              ) : attributeGroup?.type === "DOCUMENT" ? (
                //file picker
                <input
                  label="Value"
                  onChange={handleFileInputChange}
                  placeholder="Enter Attribute Value"
                  type="file"
                  className="w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"
                  accept=".txt,.pdf,.doc,.docx,.odt"
                />
              ) : (
                //normal input
                <ArbitNormalInput
                  label="Value"
                  value={attributeValue}
                  onChange={setAttributeValue}
                  placeholder={
                    attributeGroup?.label === "Email"
                      ? "E.G Hi@gmail.com"
                      : attributeGroup?.label === "Phone"
                      ? "E.G 08012345678"
                      : attributeGroup?.type === "DIMENSION"
                      ? "E.G 2.4 x 4 x 6"
                      : "Enter Attribute Value"
                  }
                  type={
                    attributeGroup?.type === "STRING"
                      ? "text"
                      : attributeGroup?.type === "EMAIL"
                      ? "email"
                      : attributeGroup?.type === "PHONE"
                      ? "tel"
                      : "text"
                  }
                  error={!valid}
                  helperText={errMessage}
                  width={"full"}
                />
              )}
            </div>
          </div>
          {!valid && (
            <div className="mt-[10px]">
              <ErrorComponent errorMessage={errMessage} />
            </div>
          )}
        </div>
        <div className="flex w-full space-x-[4px] justify-end">
          <ArbitButton
            width={"64px"}
            text_color={"blue-600"}
            bg_color={"white"}
            onClick={() => onClose()}
          >
            Cancel
          </ArbitButton>
          <ArbitButton width={"48px"} onClick={() => AddAttribute()}>
            Add
          </ArbitButton>
        </div>
      </Modal>
    </>
  );
};

export default ArbitNewAttributeModal;
