import axios from '../../middleware/axios';
import constants from '../../constant';
import {apiutils} from '../../utils';

const addProductImages = async (id, payload) => {
    const response = await axios.post(constants.APIURL.UPDATE_PRODUCT + id +"/images" , payload, {
        headers: {}
    }, {withCredentials: true})
    return response
}


const selectMainImage=async(id,imageId,payload) => {
    const response = await axios.post(constants.APIURL.SELECT_MAIN_IMAGE + id +"/images/"+ imageId , payload, {
        headers: {}
    }, {withCredentials: true})
    return response
}

const fetchAttributeGroups = async () => {
    const response = await axios.get(constants.APIURL.GET_ATTRIBUTE_GROUPS, {withCredentials: true});

    return response
}
const fetchAttributes = async () => {
    const response = await axios.get(constants.APIURL.GET_ATTRIBUTES + '?showAll=true&filter[status]=1', {withCredentials: true});

    return response
}

const fetchProduct = async (id, productLanguage) => {
    const response = await axios.get(constants.APIURL.GET_PRODUCT + '/' + id + '?locale=' + productLanguage, {withCredentials: true});
    return response
}


const fetchProducts = async (params) => {
    let url = constants.APIURL.GET_PRODUCT
    if (params)
        url = url.concat(params)
    const response = await axios.get(url, {withCredentials: true});
    return response
}

const deleteProductImage = async (id, imageId, payload) => {
    const response = await axios.delete(constants.APIURL.DELETE_IMAGE + id + '/images/' + imageId,)
    return response;
}



const UpdateAttribute = async (id, attrubuteId, payload) => {
    if (payload.value instanceof File) {
        payload = apiutils.obj2Form(payload);
    }
    const response = await axios.post(constants.APIURL.UPDATE_ATTRIBUTE + attrubuteId + '/' + id, payload, {withCredentials: true})

    return response;
}

const editAttribute = async (id, attributeId, payload) => {
    if (payload.value instanceof File) {
        payload = apiutils.obj2Form(payload);
    }
    const response = await axios.put(constants.APIURL.UPDATE_ATTRIBUTE + attributeId, payload, {withCredentials: true})

    return response;
}

const deleteAttribute = async (id, payload) => {
    const response = await axios.delete(constants.APIURL.UPDATE_ATTRIBUTE + id, {withCredentials: true, data: payload})

    return response;
}

const UpdateProduct = async (id, payload) => {
    const response = await axios.post(constants.APIURL.UPDATE_PRODUCT + id, apiutils.obj2Form(payload, ['image']), {withCredentials: true})

    return response;
}

const getBrands = async () => {
    const response = await axios.get(constants.APIURL.GET_BRANDS, {withCredentials: true});

    return response;
}

const getCategories = async () => {
    const response = await axios.get(constants.APIURL.GET_CATEGORIES, {withCredentials: true});

    return response;
}


const getProductLocale = async (params) => {
    let url = constants.APIURL.GET_LANGUAGES
    if (params)
        url = url.concat(params)
    const response = await axios.get(url, {withCredentials: true});

    // console.log(response, 'Product Locale')
    return response;
}
const getAttributeTypes = async () => {
    const response = await axios.get(constants.APIURL.GET_ATTRIBUTE_TYPES, {withCredentials: true})

    return response
}


const getRule = async (type) => {
    const response = await axios.get(constants.APIURL.GET_RULE + type, {withCredentials: true})

    return response
}

const createAttributes = async (payload) => {
    const response = await axios.post(constants.APIURL.POST_ATTRIBUTE, payload, {withCredentials: true})

    return response
}

const deleteProduct = async (id) => {
    const response = await axios.delete(constants.APIURL.DELETE_PRODUCT + id, {withCredentials: true})

    return response;
}

const createProductFromData = async (payload) => {
    const response = await axios.post(constants.APIURL.POST_PRODUCT_FROM_DATA, payload, {withCredentials: true})
    return response;
}


const duplicateProduct = async (id) => {
    const response = await axios.post(constants.APIURL.POST_PRODUCT_DUPLICATE + id + '/duplicate', {withCredentials: true})

    return response;
}

const createProduct = async (payload) => {
    const response = await axios.post(constants.APIURL.CREATE_PRODUCT, apiutils.obj2Form(payload, ['image']), {withCredentials: true})
    return response;
}

const bulkUploadProduct = async (payload) => {
    const response = await axios.get(constants.APIURL.GET_PRODUCT + '/import-manual', apiutils.obj2Form(payload), {withCredentials: true})
    return response;
}
export default {
    addProductImages,
    fetchAttributeGroups,
    fetchProduct,
    getCategories,
    selectMainImage,
    deleteProductImage,
    UpdateAttribute,
    fetchAttributes,
    UpdateProduct,
    getBrands,
    getAttributeTypes,
    getRule,
    createAttributes,
    createProductFromData,
    deleteProduct,
    duplicateProduct,
    fetchProducts,
    getProductLocale,
    createProduct,
    editAttribute,
    deleteAttribute,
    bulkUploadProduct,
}


