import React, { useEffect, useState } from "react";
import axios from "axios";

import MainModal from "../../dynamic-page/MainModal";
import Util from "../../dynamic-page/util";
import UI from "../../dynamic-form/elements";
import Icons from "../../orders/icons";
import upperCase from "../../dynamic-page/util/upperCaseString";
import SelectItems from "../components/modals/mergeorder";
import callEndpoint from "../../dynamic-page/util/callEndpoint";
import { Tooltip } from "react-tooltip";
import moment from "moment";
import { HiExternalLink } from "react-icons/hi";
import descriptiveContent from "../../../utils/descriptiveContent";
import ConfirmModal from "../../dynamic-page/util/confirmModal";
import util from "../../dynamic-page/util";

// Main Component: ConnectChannelModal
const MergeForm = React.memo(
  ({ onClose, parentItem, callBack, defaultListings, updateRows }) => {
    const mapProducts = (item) => ({
      id: item?.id,
      label: item.first_item?.item_title,
      image:  item?.first_item?.image_url,
      order_number: item?.order_numbers[0],
      account: item?.sales_channel_account?.name,
      channel: item?.sales_channel_account?.channel?.name,
      status: item?.order_status,
      date: moment(item?.created_at).format("MMM DD, YYYY"),
      has_label: item?.shipment_labels?.length > 0,
      shipment_labels: item?.shipment_labels,
      sales_channel_account: item?.sales_channel_account,
    });

    console.log("parentItem", parentItem);
    const [data, setData] = useState({
      parent: [mapProducts(parentItem)],
      children: [],
    });

    const [parent, setParent] = useState(mapProducts(parentItem));
    const [children, setChildren] = useState([]);
    const [connectLoading, setConnectLoading] = useState({});
    const [submitting, setSubmitting] = useState(false);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
      if (parentItem) {
        const fetchChildren = async (params) => {
          setLoading(true);
          let url = `api/v1/orders?filter[is_at_final_state]=false&filter[order_address_id]=${parentItem?.order_address_id}&include=shipmentLabels`;
          if (params) url = url.concat(params);
          const response = await axios.get(url, { withCredentials: true });
          setChildren(
            response.data?.data
              ?.filter((item) => item.id !== parentItem.id)
              ?.map(mapProducts)
          );
          setLoading(false);
        };
        fetchChildren();
      }
    }, []);

    const onConnect = () => {
      setSubmitting(true);
      callEndpoint({
        title: "Merge Orders",
        method: "POST",
        url: "orders/merge",
        data: {
          parent_order: parent.id,
          child_orders: data.children.map((item) => item.id),
        },
      }).then((response) => {
        setSubmitting(false);
        if (response.type === "success") {
          callBack();
          onClose();
        }
      });
    };

    const accountSection = (
      <div
        className={
          "flex flex-row gap-[4px] items-center text-gray-900 h-[1.5em] min-w-fit"
        }
      >
        {parent?.sales_channel_account?.country?.flag && (
          <Util.popUp
            toggle={(selected) => (
              <img
                src={parent?.sales_channel_account?.country?.flag}
                className={"h-[12px] w-[17px] rounded-[2px]"}
                style={{ objectFit: "cover" }}
              />
            )}
            action={"hover"}
          >
            <div className={"px-[8px] py-[4px]"}>
              {" "}
              {parent?.sales_channel_account?.country?.name}{" "}
            </div>
          </Util.popUp>
        )}

        {parent?.sales_channel_account?.channel?.marketplace?.logo && (
          <img
            src={parent?.sales_channel_account?.channel?.marketplace?.logo}
            className={"h-[12px] "}
          />
        )}
        {parent?.sales_channel_account?.name && (
          <span className={"text-[14px h-18px items-center flex"}>
            {upperCase(parent?.sales_channel_account?.name)}
          </span>
        )}
      </div>
    );

    return (
      <MainModal
        className="max-w-[1000px]"
        containerClassName="!px-5"
        option={
          <div
            className={
              "flex flex-row gap-[4px] items-center text-gray-900 h-[1.5em] min-w-fit"
            }
          >
            {parentItem?.sales_channel_account?.country?.flag && (
              <Util.popUp
                toggle={(selected) => (
                  <img
                    src={parentItem?.sales_channel_account?.country?.flag}
                    className={"h-[12px] w-[17px] rounded-[2px]"}
                    style={{ objectFit: "cover" }}
                  />
                )}
                action={"hover"}
              >
                <div className={"px-[8px] py-[4px]"}>
                  {" "}
                  {parentItem?.sales_channel_account?.country?.name}{" "}
                </div>
              </Util.popUp>
            )}

            {parentItem?.sales_channel_account?.channel?.marketplace?.logo && (
              <img
                src={
                  parentItem?.sales_channel_account?.channel?.marketplace?.logo
                }
                className={"h-[12px] "}
              />
            )}
            {parentItem?.sales_channel_account?.name && (
              <span
                className={
                  "text-[14px] font-medium text-gray-900 items-center flex"
                }
              >
                {upperCase(parentItem?.sales_channel_account?.name)}
              </span>
            )}
          </div>
        }
        item={{
          title: "Merge Orders",
          cancel: onClose,
          view: () => (
            <div className="app flex flex-col gap-3 border-t pt-4 border-gray-300">
              <SelectItems
                title="Parent Order"
                mapItems={mapProducts}
                createNewRedirect={true}
                defaultItem={parent}
                ItemCard={ProductItem}
                key="1"
                data={data}
                setData={setData}
                tooltip={parentTooltip}
                parent={parent}
              />
              <SelectItems
                title="Mergable Orders"
                mapItems={mapProducts}
                createNewRedirect={false}
                ItemCard={ProductItem}
                defaultItem={children}
                setChildren={setChildren}
                parent={parent}
                multiple
                setParent={setParent}
                key="2"
                data={data}
                setData={setData}
                loading={loading}
                connectLoading={connectLoading}
                newDesign={true}
                tooltip={childrenTooltip}
              />
              {data?.parent?.length > 0 && data?.children?.length > 0 && (
                <div className="flex flex-row justify-end gap-[8px] sticky bg-white bottom-0 pb-2">
                  <UI.Button
                    item={{
                      label: <div className={"flex flex-row"}>Cancel</div>,
                      buttonType: "alt",
                      className: "h-[37px] !w-[120px] !px-[16px]",
                    }}
                    onClick={() => onClose()}
                  />
                  <UI.Button
                    item={{
                      label: <div className={"flex flex-row"}>Merge</div>,
                      buttonType: "primary",
                      className: "h-[37px] !w-[120px]  !px-[16px]",
                      disabled: submitting,
                    }}
                    onClick={() => onConnect()}
                  />
                </div>
              )}
            </div>
          ),
        }}
      />
    );
  }
);

export default MergeForm;

const ProductItem = ({
  item,
  index,
  results,
  setData,
  data,
  setParent,
  setChildren,
  parent,
}) => {
  const primaryShipmentLabel = item?.shipment_labels?.find(
    (label) => label.is_primary
  );
  const [confirmationModalVisibility, setConfirmationModalVisibility] =
    useState(false);
  return (
    <div
      key={index}
      className={`${
        data.children?.find((channel) => channel.id === item.id)
          ? "bg-blue-50"
          : "bg-white"
      } w-[630px] 
        flex items-center p-3 mb-2 border border-solid shadow-sm  rounded-lg`}
    >
      <img
        src={item?.image || "/assets/images/noImage.png"}
        alt={item?.label}
        className="result-image w-[120px] h-[120px] mr-4 object-contain rounded"
      />
      <div
        className={`result-details flex-1 flex flex-col justify-between ${
          parent?.id === item?.id ? "h-[152px]" : "h-[120px]"
        }`}
      >
        {/* apid and badge here */}
        <div className="flex justify-between items-center">
          <div className={"flex flex-row gap-[4px]"}>
            <div className=" text-sm text-gray-900 font-medium leading-[18px] hover:text-blue-800 cursor-pointer">
              {item?.order_number}
            </div>
            <Util.copyText
              className={"text-[12px]"}
              text={item?.order_number}
              hint={false}
            />
            <HiExternalLink
              className="w-4 h-4  text-blue-500 cursor-pointer"
              onClick={() =>
                window.open(
                  `/logistics/orders/#action=details&orderId=${item?.id}
                `,
                  "_blank"
                )
              }
            />
          </div>
          {
            <div className=" text-sm  text-gray-900 font-medium leading-[18px] ">
              {item?.date}
            </div>
          }
        </div>
        {/* title here */}
        <div>
          <p className="text-[16px] leading-[24px] text-gray-900 font-semibold whitespace-pre-wrap">
          { 
            parent?.id === item?.id ?   item?.label?.length > 230 ?     
                Util.shortenString(item?.label, 230, false) 
                : item?.label : item?.label?.length > 170 ?
                  Util.shortenString(item?.label, 170, false)
                  : item?.label
            }
          </p>
        </div>
        {/* numbers here */}
        {parent?.id !== item?.id ? (
          <div className="flex justify-between">
            {!item?.has_label ? (
              <div
                className={
                  "flex flex-row gap-[4px] text-[14px] font-medium text-gray-500"
                }
              >
                {item?.status}
              </div>
            ) : (
              <div
                className={
                  "flex flex-row gap-[4px] text-[14px] font-medium text-red-800"
                }
              >
                has a shipping label
                <Icons.info
                  className="w-5 h-5  text-red-800"
                  data-tooltip-id="forceTooltip"
                />
                <Tooltip
                  id="forceTooltip"
                  place="top"
                    positionStrategy="fixed"
                  className="z-[99999] bg-white !opacity-100"
                  style={{
                    backgroundColor: "white",
                    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
                    borderRadius: "4px",
                  }}
                >
                  <div className="flex flex-col gap-1.5 w-[320px]">
                    <p className="text-[14px] font-medium text-gray-900">
                      Replace Parent or Force Select!
                    </p>
                    <p className="text-xs text-gray-500 whitespace-pre-wrap">
                      <span className="font-bold text-gray-500">
                        Orders with a shipping label created can only be parent.
                      </span>
                      So there is no confusion of multiple active tracking
                      numbers in merged orders
                      <br />
                      Please void the shipping label to the logistics provider
                      by{" "}
                      <span className="font-bold text-gray-500">
                        Force Select{" "}
                      </span>{" "}
                      or{" "}
                      <span className="font-bold text-gray-500">
                        replace this order with the parent order.
                      </span>
                    </p>
                  </div>
                </Tooltip>
              </div>
            )}
            <div className="flex justify-end gap-8">
              <button
                className={` text-[14px] flex items-center gap-1  font-medium text-blue-700`}
                onClick={() => {
                  setChildren((prev) => [
                    ...prev.filter((order) => order.id !== item.id),
                    parent,
                  ]);
                  setParent(item);
                  setData((prev) => ({
                    ...prev,
                    children: prev.children.filter(
                      (channel) => channel.id !== item.id
                    ),
                  }));
                }}
              >
                <Icons.arrowRotate className="w-4 h-4  text-blue-700 cursor-pointer" />
                Replace parent
              </button>
              <button
                className={` text-[14px] font-medium ${
                  !data.children?.find((channel) => channel.id === item.id)
                    ? "text-blue-700"
                    : "text-red-700"
                }`}
                onClick={() => {
                  if (
                    data.children?.find((channel) => channel.id === item.id)
                  ) {
                    setData((prev) => ({
                      ...prev,
                      children: prev.children.filter(
                        (channel) => channel.id !== item.id
                      ),
                    }));
                  } else {
                    if (item?.has_label) {
                      setConfirmationModalVisibility(true);
                      return;
                    }
                    setData((prev) => ({
                      ...prev,
                      children: prev.children.concat(
                        results.find((channel) => channel.id === item.id)
                      ),
                    }));
                  }
                }}
              >
                {data.children?.find((channel) => channel.id === item.id)
                  ? "Deselect"
                  : item?.has_label
                  ? "Force Select"
                  : "Select"}
              </button>
            </div>
          </div>
        ) : (
          <div
            className={
              "flex justify-between text-[14px] font-medium text-gray-900"
            }
          >
            <div
              className={
                "flex flex-row gap-[4px] text-[14px] font-medium text-gray-500"
              }
            >
              {item?.has_label ? (
                <div
                  className={
                    "flex flex-row gap-[4px] box-content w-full items-center"
                  }
                >
                  {descriptiveContent(
                    primaryShipmentLabel?.international_carrier?.logo ? (
                      <img
                        className={"min-w-[20px] h-[20px]"}
                        src={primaryShipmentLabel?.international_carrier?.logo}
                      />
                    ) : (
                      <Icons.track className={"w-[16px] h-4 text-gray-300"} />
                    ),
                    primaryShipmentLabel?.international_carrier?.name
                  )}
                  <span
                    onClick={() => {
                      if (primaryShipmentLabel?.tracking_url) {
                        window.open(
                          primaryShipmentLabel?.tracking_url,
                          "_blank"
                        );
                      }
                    }}
                    className={
                      "cursor-pointer flex flex-row gap-[4px] items-center"
                    }
                  >
                    <span>{primaryShipmentLabel?.tracking_number}</span>
                    {primaryShipmentLabel?.tracking_url && (
                      <Icons.goToTrackURL className={"text-blue-700 w-[9px]"} />
                    )}
                  </span>
                  <Util.copyText
                    className={"text-[12px]"}
                    text={primaryShipmentLabel?.tracking_number}
                    hint={""}
                  />
                </div>
              ) : (
                ""
                //         <div className="flex justify-between items-center">
                //           <div className={"flex flex-row gap-[4px] text-gray-900"}>
                //     {item?.account ? (
                //       <Util.copyText
                //         className={"text-[14px] font-medium text-gray-900"}
                //         text={item?.brand}
                //         hint={false}
                //       >
                //         {Util.shortenString(upperCase(item?.account), 30)}{" "}
                //       </Util.copyText>
                //     ) : (
                //       ""
                //     )}
                //   </div>

                //   <div className="flex items-center gap-[2px] ext-[14px] font-medium text-gray-900">{item?.channel}</div>
                // </div>
              )}
            </div>
          </div>
        )}
      </div>
      {confirmationModalVisibility && (
        <ConfirmModal
          show={confirmationModalVisibility}
          key={item.id}
          infoText={"Are you sure you force select this order as parent?"}
          onClose={() => setConfirmationModalVisibility(null)}
          confirmText={"Yes, I'm sure"}
          className="z-[9999]"
          confirmColor="green"
          cancelText="Cancel"
          cancelColor="red"
          styles={{
            confirmButton: "!bg-green-50 ",
            cancelButton: "!bg-red-50 ",
            infoText: "!font-medium",
          }}
          onConfirm={(setSubmitting) => {
            setSubmitting(true);
            callEndpoint({
              method: "POST",
              url: "orders/shipment-labels/mark-as-voided",
              data: {
                ids: item?.shipment_labels?.map((label) => label.id),
              },
            }).then((response) => {
              setSubmitting(false);
              util.notifier(response)
              if (response.type === "success") {
                setConfirmationModalVisibility(false);
                setData((prev) => ({
                  ...prev,
                  children: prev.children.concat(
                    results.find((channel) => channel.id === item.id)
                  ),
                }));
              }
            });
          }}
        />
      )}
    </div>
  );
};

const parentTooltip = (
  <>
    <span className="flex ">
      <Icons.info
        className="w-5 h-5  text-gray-300"
        data-tooltip-id="productTooltip"
      />
    </span>
    <Tooltip
      id="productTooltip"
      place="top"
      positionStrategy="fixed"
      className="z-[99999] bg-white !opacity-100"
      style={{
        backgroundColor: "white",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
        borderRadius: "4px",
      }}
    >
      <div className="flex flex-col gap-1.5 w-[320px]">
        <p className="text-[14px] font-medium text-gray-900">
          What’s the Parent Order?
        </p>
        <p className="text-xs text-gray-500 whitespace-pre-wrap">
          All selected orders will be merged under this Parent Order. No order
          items will be lost during the merge.In case of any data discrepancies
          (e.g., phone number), the Parent Order's data will be used.
        </p>
      </div>
    </Tooltip>
  </>
);

const childrenTooltip = (
  <>
    <span className="flex ">
      <Icons.info
        className="w-5 h-5  text-gray-300"
        data-tooltip-id="my-tooltip"
      />
    </span>
    <Tooltip
      id="my-tooltip"
      place="top"
      positionStrategy="fixed"
      className="z-[99999] bg-white !opacity-100"
      style={{
        backgroundColor: "white",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
        borderRadius: "4px",
      }}
    >
      <div className="flex flex-col gap-1.5 w-[320px]">
        <p className="text-[14px] font-medium text-gray-900">
          Can't see the order you wanted to merge?
        </p>
        <p className="text-xs text-gray-500 whitespace-pre-wrap">
          <span className="font-bold text-gray-500"></span>
          Orders must have{" "}
          <span className="font-bold text-gray-500">
            the same customer address
          </span>{" "}
          and come from{" "}
          <span className="font-bold text-gray-500">
            {" "}
            the same sales channel account{" "}
          </span>{" "}
          to be mergeable.
          <br />
          <span className="font-bold text-gray-500">
            You can not see orders that do not meet these criteria as mergeable.{" "}
          </span>
        </p>
      </div>
    </Tooltip>
  </>
);
