import React from "react";
import EstTransferCost from "../components/tooltipInput";
import Icons from "../../orders/icons";
import Note from "../components/note";
import descriptiveContent from "../../../utils/descriptiveContent";



const tableView = (list, tableActions,actionList) => {
  const activeStyle = (status) => {
    if (status) {
      return "!text-blue-500";
    } else {
      return "text-gray-300 hover:text-gray-500";
    }
  };
  return {
    list: list ?? [],
    theme: {
      name: (item) => (
        <div>
          <span className="font-semibold text-[14px] text-gray-900 leading-[14px] ">
            {item?.name}
          </span>
        </div>
      ),
      provider: (item) => (
        <div>
          <span className="font-medium text-[14px] text-[rgba(17, 25, 40, 1)] leading-[21px] ">
            {item?.provider}
          </span>
        </div>
      ),
      country: (item) => (
        <div className="flex gap-2 items-center">
          <img
            className="w-[22.4px] h-[16px] object-cover"
            src={item?.country?.flag}
          />
          <span className="text-[14px] leading-normal font-medium text-gray-900">
            {item?.country?.name}
          </span>
        </div>
      ),
      "PUP tRANSFER COST Est.": (item) => (
        <div className="">
          <EstTransferCost item={item} type="transfer_cost" key={`transfer${item?.id}`}
           onSubmit={tableActions.onUpdateValue} buttonText={'Set Transfer Cost'} title={'PUP Transfer Cost'} />
        </div>
      ),
      "PUP processıng fee": (item) => (
        <div className="">
          <EstTransferCost item={item} type="processing_fee" key={`processing${item?.id}`} onSubmit={tableActions.onUpdateValue} buttonText={'Set Processing Fee'} title={'PUP Processing Fee'} />
        </div>
      ),
      "": (item) => (
        <div className="flex gap-4 items-center">
          <div className="flex justify-between items-center gap-1 ">
            <Note item={item} callBack={tableActions.onUpdateNote} />
          </div>
          <button>
            {descriptiveContent(<Icons.editOutline 
              onClick={() => actionList.edit.onClick(item)}
              className="w-[18px] h-[18px] text-gray-300 cursor-pointer hover:text-gray-500"
            /> , 'Edit',
            null,
            "top")}
          </button>
          <button>
            {descriptiveContent(<Icons.trashBin 
              onClick={() => actionList.delete.onClick(item)}
              className="w-[21px] h-[21px] text-gray-300 cursor-pointer hover:text-gray-500"
            /> , 'Delete',
            null,
            "top")}
          </button>
        </div>
      ),

    },
  };
};
export default tableView;
